import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { localStorageGetItem } from '@rankacy/utils';
import { defaultLang } from './config';

import enApiErrors from './locales/api-errors/en.json';
import enCommon from './locales/common/en.json';
import enAdministration from './locales/administration/en.json';
import enRankacy from './locales/rankacy/en.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export enum Namespaces {
  ApiErrors = 'api-errors',
  Rankacy = 'rankacy',
  Administration = 'administration',
  Common = 'common',
}

export const API_ERRORS_PREFIX = 'keyword_';

const functionName = (name: string) => name.charAt(0).toLowerCase() + name.slice(1);

const generateTranslationHelpers = (namespaces: typeof Namespaces) =>
  Object.keys(namespaces).reduce(
    (acc, namespace) => ({
      ...acc,
      [functionName(namespace)]: (translationKey: string) =>
        `${Namespaces[namespace as keyof typeof Namespaces]}:${translationKey}`,
    }),
    {} as Record<string, (translationKey: string) => string>
  );

export const { apiErrors, common, rankacy, administration } =
  generateTranslationHelpers(Namespaces);

export const resources = {
  en: {
    [Namespaces.ApiErrors]: enApiErrors,
    [Namespaces.Common]: enCommon,
    [Namespaces.Rankacy]: enRankacy,
    [Namespaces.Administration]: enAdministration,
  },
};

export const initIntl = (defaultNS = Namespaces.Rankacy) => {
  const lng = localStorageGetItem('i18nextLng', defaultLang.value);

  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng,
      fallbackLng: lng,
      debug: false,
      ns: Object.values(Namespaces),
      defaultNS,
      interpolation: {
        escapeValue: false,
      },
    });
};

export const setLanguage = (lng: string) => i18n.changeLanguage(lng);

export * from './use-locales';
export * from './config';
