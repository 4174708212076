import { useState, useEffect, useRef, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { appBarClasses } from '@mui/material/AppBar';
import Popover, { popoverClasses } from '@mui/material/Popover';
import { NavListProps, NavConfigProps } from '../types';
import { NavItem } from './nav-item';
import { useActiveLink, usePathname } from '@rankacy/routing';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
};

export const NavList = ({ data, depth, hasChild, config }: NavListRootProps) => {
  const navRef = useRef(null);

  const pathname = usePathname();

  const active = useActiveLink(data.path, hasChild);

  const externalLink = data.path.includes('http');

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const appBarEl = Array.from(
      document.querySelectorAll(`.${appBarClasses.root}`)
    ) as Array<HTMLElement>;

    // Reset styles when hover
    const styles = () => {
      document.body.style.overflow = '';
      document.body.style.padding = '';
      // Apply for Window
      appBarEl.forEach((elem) => {
        elem.style.padding = '';
      });
    };

    if (open) {
      styles();
    } else {
      styles();
    }
  }, [open]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <NavItem
        active={active}
        config={config}
        depth={depth}
        externalLink={externalLink}
        item={data}
        open={open}
        ref={navRef}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      />

      {hasChild && (
        <Popover
          anchorEl={navRef.current}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          open={open}
          slotProps={{
            paper: {
              onMouseEnter: handleOpen,
              onMouseLeave: handleClose,
            },
          }}
          sx={{
            pointerEvents: 'none',
            [`& .${popoverClasses.paper}`]: {
              mt: 0.5,
              width: 160,
              ...(open && {
                pointerEvents: 'auto',
              }),
            },
          }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        >
          <NavSubList config={config} data={data.children} depth={depth} />
        </Popover>
      )}
    </>
  );
};

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
};

const NavSubList = ({ data, depth, config }: NavListSubProps) => (
  <Stack spacing={0.5}>
    {data.map((list) => (
      <NavList
        config={config}
        data={list}
        depth={depth + 1}
        hasChild={!!list.children}
        key={list.title + list.path}
      />
    ))}
  </Stack>
);
