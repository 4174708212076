import { alpha, Theme } from '@mui/material/styles';

const Skeleton = (theme: Theme) => ({
  MuiSkeleton: {
    defaultProps: {
      animation: 'wave',
      variant: 'rounded',
    },
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.grey[500], 0.3),
      },
      rounded: {
        borderRadius: theme.shape.borderRadius * 2,
      },
    },
  },
});

export default Skeleton;
