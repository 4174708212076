import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api, demoUploadingName, demoUploadingReducer } from '@rankacy/services';

const createStore = () =>
  configureStore({
    reducer: {
      [demoUploadingName]: demoUploadingReducer,
      [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([api.middleware]),
  });

export const store = createStore();

setupListeners(store.dispatch);
