import {
  useGetSteamCodeApiUserSteamGetCodesGetQuery,
  useUserMatchCountApiGamesUserMatchCountGetQuery,
} from '@rankacy/services';

export const useSteamConnectionAndGamesStatus = () => {
  const {
    data: gamesCount,
    status: gamesStatus,
    isLoading: isLoadingGames,
  } = useUserMatchCountApiGamesUserMatchCountGetQuery();

  const {
    data: steamConnectionInfo,
    status: steamConnectionInfoStatus,
    isLoading: isLoadingSteamConnectionInfo,
  } = useGetSteamCodeApiUserSteamGetCodesGetQuery();

  return {
    isConnectedToSteam: !!steamConnectionInfo?.steam_id,
    hasAnyGames: !!gamesCount,
    statuses: [steamConnectionInfoStatus, gamesStatus],
    isLoading: isLoadingGames || isLoadingSteamConnectionInfo,
    autoDownloadCodesProvided:
      !!steamConnectionInfo?.match_code && !!steamConnectionInfo?.auth_code,
  };
};
