/**
 * Creates a URL with appended search parameters.
 *
 * @param url The base URL to which the search parameters will be appended.
 * @param params An object containing key-value pairs representing the search parameters.
 * @returns A string representing the URL with the appended search parameters.
 */
export const createUrlWithSearchParams = (url: string, params: Record<string, string>) => {
  const searchParams = new URLSearchParams(params);

  return `${url}?${searchParams.toString()}`;
};
