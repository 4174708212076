import { memo } from 'react';
import { StyledBackgroundPattern } from '../styles';

type Props = {
  type?: 'half' | 'full';
};

const BackgroundPattern = ({ type = 'full' }: Props) => (
  <StyledBackgroundPattern
    src={`/assets/background/half_logo_overlay_${type === 'full' ? 'blue' : 'grey'}.webp`}
  />
);

export default memo(BackgroundPattern);
