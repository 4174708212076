import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY } from '../../../auth';
import { WS_BASE_URL } from '../../../config-global';

export const createSocketURL = () => {
  try {
    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);

    return `${WS_BASE_URL}/track/?token=${accessToken}`;
  } catch (error) {
    throw new Error(`Error while creating socket URL: ${error}`);
  }
};
