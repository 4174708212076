import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useLocales } from '@rankacy/intl';
import { HEADER } from '../config-layout';
import { useFeature } from '@rankacy/feature-flags';

type Props = {
  backgroundColor?: string;
  textColor?: string;
};

export const GlobalAnnouncement = ({ backgroundColor, textColor }: Props) => {
  const { t } = useLocales();

  const isGiveawayRunning = useFeature('VITE_FEATURE_GIVEAWAY').on;

  const giveawayText = (
    <Typography color={textColor} sx={{ fontSize: { xs: 11, md: 13 } }} variant="overline">
      {`You're in alpha testing – bugs ahead! 🐞 BIG SKINS GIVEAWAY IS LIVE, WORTH €850 - `}
      <Link
        color="grey.800"
        href="https://x.com/rankacy"
        rel="noopener noreferr"
        sx={{ textDecoration: 'underline' }}
        target="_blank"
      >
        GET DETAILS!
      </Link>{' '}
      🎯
    </Typography>
  );

  const genericText = (
    <Typography color={textColor} sx={{ fontSize: { xs: 11, md: 13 } }} variant="overline">
      {t('global_announcement_before_link')}
      <Link
        color="grey.800"
        href="https://discord.gg/WUyQbwbHaJ"
        rel="noopener noreferr"
        sx={{ textDecoration: 'underline', fontWeight: '900' }}
        target="_blank"
      >
        Discord
      </Link>
      {t('global_announcement_after_link')}
    </Typography>
  );

  return (
    <Box
      sx={{
        width: 1,
        height: HEADER.H_ANNOUNCEMENT,
        bgcolor: backgroundColor,
        textAlign: 'center',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
      }}
    >
      {isGiveawayRunning ? giveawayText : genericText}
    </Box>
  );
};
