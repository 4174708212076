import { Button, Container, Stack } from '@mui/material';
import { RouterLink } from '@rankacy/routing';
import { useLocales } from '@rankacy/intl';
import { useResponsive } from '@rankacy/hooks';
import { paths } from '../../../routes/paths';
import { HomeFeatureCard } from '../home-feature-card';
import { HomeCarouselCard } from '../home-carousel-card';
import { HomeGetStartedCard } from '../home-get-started-card';
import { HomeGetStartedSteps } from '../home-get-started-steps';
import { HomeJoinCommunityCard } from '../home-join-community-card';
import { CAROUSEL_CARDS, FEATURE_CARDS, LEVEL_UP_CARD } from '../types';
import { HeroSection } from '../hero-section';
import { useRef } from 'react';

const HomeView = () => {
  const { t } = useLocales();

  const mdDown = useResponsive('down', 'md');

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <HeroSection
        actions={
          <Button component={RouterLink} href={paths.register} size="large" variant="contained">
            {t('join_alpha')}
          </Button>
        }
        subtitle="landing_page.hero_text"
        title="landing_page.hero_title"
      />

      <Container
        ref={containerRef}
        sx={{
          gap: { xs: 7.5, lg: 15 },
          display: 'flex',
          flexDirection: 'column',
          mt: { xs: 7.5, lg: 15 },
          px: '0px !important',
          position: 'relative',
        }}
      >
        {/* <PlayerScroll containerRef={containerRef} /> */}

        <Container>
          <Stack sx={{ gap: 3, flexDirection: { xs: 'column', md: 'row' } }}>
            {FEATURE_CARDS(mdDown).map((card, idx) => (
              <HomeFeatureCard key={idx} {...card} />
            ))}
          </Stack>
        </Container>

        <Container sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 3, lg: 4 } }}>
          <HomeCarouselCard disableCarousel data={LEVEL_UP_CARD} />

          <HomeCarouselCard data={CAROUSEL_CARDS(mdDown)} />
        </Container>

        <Container sx={{ display: 'flex', flexDirection: 'column' }}>
          <HomeGetStartedCard />

          <HomeGetStartedSteps />
        </Container>

        {/* <Container>
          <HomePricingSection />
        </Container> */}

        <Container>
          <HomeJoinCommunityCard />
        </Container>
      </Container>
    </>
  );
};

export default HomeView;
