// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { paths } from '../../routes/paths';
import { useAuthContext } from '../../auth';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';
import { RouterLink } from '@rankacy/routing';

const loginPaths: Record<string, string> = {
  jwt: paths.login,
};

type Props = {
  sx?: SxProps<Theme>;
};

const LoginButton = ({ sx }: Props) => {
  const { t } = useLocales();
  const { method } = useAuthContext();

  const loginPath = loginPaths[method];

  return (
    <Button
      component={RouterLink}
      href={loginPath}
      sx={{ height: pxToRem(42), px: pxToRem(32), fontSize: pxToRem(16), ...sx }}
      variant="outlined"
    >
      {t('login')}
    </Button>
  );
};

export default LoginButton;
