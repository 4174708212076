import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Logo } from '../logo';

export const SplashScreen = ({ sx, ...other }: BoxProps) => (
  <Box
    sx={{
      right: 0,
      width: 1,
      bottom: 0,
      height: 1,
      zIndex: 9998,
      display: 'flex',
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'background.default',
      ...sx,
    }}
    {...other}
  >
    <>
      <m.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo disabledLink variant="small" />
      </m.div>

      <Box
        animate={{
          scale: [1.6, 1, 1, 1.6, 1.6],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        component={m.div}
        sx={{
          width: 70,
          height: 70,
          position: 'absolute',
          border: (theme) => `solid 3px ${alpha(theme.palette.primary.main, 0.24)}`,
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
      />

      <Box
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        component={m.div}
        sx={{
          width: 90,
          height: 90,
          position: 'absolute',
          border: (theme) => `solid 8px ${alpha(theme.palette.primary.main, 0.24)}`,
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
      />
    </>
  </Box>
);
