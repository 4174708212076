import { m, domMax, LazyMotion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
};

export const MotionLazy = ({ children }: Props) => (
  <LazyMotion strict features={domMax}>
    <m.div style={{ height: '100%' }}> {children} </m.div>
  </LazyMotion>
);
