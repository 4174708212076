import { alpha, Theme } from '@mui/material/styles';
import { tabClasses } from '@mui/material/Tab';

const Tabs = (theme: Theme) => ({
  MuiTabs: {
    defaultProps: {
      textColor: 'inherit',
      variant: 'scrollable',
      allowScrollButtonsMobile: true,
      TabIndicatorProps: { hidden: true },
    },
    styleOverrides: {
      scrollButtons: {
        width: 30,
        borderRadius: '50%',
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
      iconPosition: 'start',
    },
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.primary.darker, 0.24),
        opacity: 1,
        paddingX: theme.spacing(2),
        borderRadius: theme.spacing(0.8),
        color: theme.palette.text.primary,
        minHeight: 40,
        height: 40,
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.subtitle1.fontSize,
        '&:not(:last-of-type)': {
          marginRight: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
          },
        },
        [`&.${tabClasses.selected}`]: {
          background: theme.palette.backgroundGradients.primary,
          color: theme.palette.grey[800],
        },
      },
    },
  },
});

export default Tabs;
