import { Theme, alpha } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';

const Table = (theme: Theme) => ({
  MuiTableContainer: {
    styleOverrides: {
      root: {
        position: 'relative',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        [`&.${tableRowClasses.hover}`]: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.darker, 0.08),
          },
        },
        [`&.${tableRowClasses.selected}`]: {
          backgroundColor: alpha(theme.palette.primary.dark, 0.04),
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.darker, 0.08),
          },
        },
        // Targeting the first row of the table body only (highlighting logged user's row on the top of the table)
        [`&:first-of-type.${tableRowClasses.root}`]: {
          [`tbody & .${tableCellClasses.root}:first-of-type`]: {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRight: 'none',
            backgroundColor: theme.palette.grey[900],
          },
          [`tbody & .${tableCellClasses.root}`]: {
            borderTop: `1px solid ${theme.palette.primary.main}`,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.grey[900],
          },
          [`tbody & .${tableCellClasses.root}:last-child`]: {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.grey[900],
            borderLeft: 'none',
          },
        },
        '&:last-of-type': {
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        },
        [`tbody & .${tableCellClasses.root}`]: {
          borderBottom: `1px solid ${theme.palette.grey[600]}`,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: 14,
        color: theme.palette.text.secondary,
      },
      stickyHeader: {
        backgroundColor: 'transparent!important',
        backdropFilter: 'blur(10px)',
      },
      paddingCheckbox: {
        paddingLeft: theme.spacing(1),
      },
    },
  },
  MuiTablePagination: {
    defaultProps: {
      backIconButtonProps: {
        size: 'small',
      },
      nextIconButtonProps: {
        size: 'small',
      },
    },
    styleOverrides: {
      root: {
        width: '100%',
      },
      toolbar: {
        height: 64,
      },
      actions: {
        marginRight: 8,
      },
      select: {
        paddingLeft: 8,
        '&:focus': {
          borderRadius: theme.shape.borderRadius,
        },
      },
      selectIcon: {
        right: 4,
        width: 16,
        height: 16,
        top: 'calc(50% - 8px)',
      },
    },
  },
});

export default Table;
