import { Theme } from '@mui/material/styles';

const Tooltip = (theme: Theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[isLight ? 800 : 600],
          color: theme.palette.text.primary,
        },
        arrow: {
          color: theme.palette.grey[isLight ? 800 : 600],
        },
      },
    },
  };
};

export default Tooltip;
