import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import { NavItemProps, NavConfigProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import { Iconify } from '@rankacy/iconify';
import { RouterLink } from '@rankacy/routing';

type Props = NavItemProps & {
  config: NavConfigProps;
};

export const NavItem = ({ item, open, depth, active, config, externalLink, ...other }: Props) => {
  const { title, path, icon, info, children, disabled, caption } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem
      disableGutters
      active={active}
      config={config}
      depth={depth}
      disabled={disabled}
      {...other}
    >
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
          }}
          secondary={
            caption ? (
              <Tooltip placement="top-start" title={caption}>
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          icon={open ? 'eva:arrow-ios-upward-outline' : 'eva:arrow-ios-downward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
          width={16}
        />
      )}
    </StyledItem>
  );

  // External link
  if (externalLink)
    return (
      <Link
        color="inherit"
        href={path}
        rel="noopener"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
        target="_blank"
        underline="none"
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link
      color="inherit"
      component={RouterLink}
      href={path}
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
      underline="none"
    >
      {renderContent}
    </Link>
  );
};
