import { Theme } from '@mui/material/styles';
import { sliderClasses } from '@mui/material/Slider';

const Slider = (theme: Theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        root: {
          [`&.${sliderClasses.disabled}`]: {
            color: theme.palette.action.disabled,
          },
          '&$focused, &$activated, &$jumped, &:hover': {
            boxShadow: 'none',
          },
        },
        rail: {
          opacity: 1,
          backgroundColor: theme.palette.grey[500],
        },
        mark: {
          backgroundColor: theme.palette.grey[300],
        },
        markLabel: {
          fontSize: 13,
          color: theme.palette.text.disabled,
        },
        valueLabel: {
          borderRadius: 8,
          backgroundColor: theme.palette.grey[isLight ? 800 : 700],
        },
      },
    },
  };
};

export default Slider;
