import { useCallback, useSyncExternalStore } from 'react';

export const useLocalStorageSyncedState = <T extends string>(key: string) => {
  const setSyncedState = useCallback(
    (newValue: T) => {
      window.localStorage.setItem(key, newValue);

      window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
    },
    [key]
  );

  const getState = () => localStorage.getItem(key) as T;

  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener);

    return () => window.removeEventListener('storage', listener);
  };

  const store = useSyncExternalStore(subscribe, getState);

  return [store, setSyncedState] as const;
};
