import { z } from 'zod';
import { FeatureFlagsType, featureFlagsSchema } from '@rankacy/feature-flags';
import { paths } from './routes/paths';

declare global {
  namespace NodeJS {
    type ProcessEnv = EnvironmentVariablesSchemaType;
  }
}

type EnvironmentVariablesSchemaType = z.infer<typeof environmentVariablesSchema>;

const baseEnvironmentVariablesSchema = z.object({
  VITE_BACKEND_BASE_URL: z.string(),
  VITE_LAST_BUILD_TIME: z.string().optional(),
});

const environmentVariablesSchema = baseEnvironmentVariablesSchema.merge(featureFlagsSchema);

const getMissingVariables = (): string[] => {
  const schemaKeys = environmentVariablesSchema.shape;

  return Object.keys(schemaKeys)
    .filter((key) => {
      const schemaKey = key as keyof typeof schemaKeys;

      return schemaKeys[schemaKey]._def.typeName !== 'ZodOptional';
    })
    .filter((key) => !import.meta.env[key as keyof EnvironmentVariablesSchemaType]);
};
const result = ((): z.infer<typeof environmentVariablesSchema> => {
  try {
    return environmentVariablesSchema.parse(import.meta.env);
  } catch {
    throw Error(
      `
        Incorrect environment setup, refer to documentation [https://esportdynamics.atlassian.net/wiki/spaces/RD/pages/39682126/FE+-+Environment+variables].
        
        This application requires ${JSON.stringify(
          Object.keys(environmentVariablesSchema.keyof().Values)
        )} environment variables.

        Missing variables: ${getMissingVariables().join(', ')}
      `
    );
  }
})();

const env = <K extends keyof EnvironmentVariablesSchemaType>(
  key: K
): EnvironmentVariablesSchemaType[K] => result[key];

// ------------------- Default Environment Variables ------------------
export const { BASE_URL, DEV, MODE, PROD, SSR } = import.meta.env;

// ------------------- Custom Environment Variables -------------------
// BACKEND BASE URL
export const BACKEND_BASE_URL = env('VITE_BACKEND_BASE_URL');
// API BASE URL
export const API_BASE_URL = `${BACKEND_BASE_URL}`;
// WEB SOCKET BASE URL
export const WS_BASE_URL = `${BACKEND_BASE_URL}/ws`;
// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root;

// FEATURE FLAGS
export const FEATURE_FLAGS: FeatureFlagsType = {
  VITE_FEATURE_REALTIME_COMMUNICATION: env('VITE_FEATURE_REALTIME_COMMUNICATION'),
  VITE_FEATURE_SOCIALS: env('VITE_FEATURE_SOCIALS'),
  VITE_FEATURE_STRATEGY_BOARD: env('VITE_FEATURE_STRATEGY_BOARD'),
  VITE_FEATURE_NOTIFICATIONS: env('VITE_FEATURE_NOTIFICATIONS'),
  VITE_FEATURE_STREETVIEW: env('VITE_FEATURE_STREETVIEW'),
  VITE_FEATURE_GLOBAL_ANNOUNCEMENT: env('VITE_FEATURE_GLOBAL_ANNOUNCEMENT'),
  VITE_FEATURE_GIVEAWAY: env('VITE_FEATURE_GIVEAWAY'),
  VITE_FEATURE_MAINTENANCE: env('VITE_FEATURE_MAINTENANCE'),
};
// LAST BUILD TIME (AVAILABLE ONLY IN PROD MODE)
export const LAST_BUILD_TIME = env('VITE_LAST_BUILD_TIME');

// GLOBAL TICK RATE
export const TICKS_PER_SECOND = 64;

if (LAST_BUILD_TIME) {
  localStorage.setItem('LAST_BUILD_TIME', LAST_BUILD_TIME);
}

export const SENTRY_DSN =
  'https://adc6d4a4b9434d6d9645b3fcd9ec62e8@o4507808804241408.ingest.de.sentry.io/4507811384983632';

export const APP_NAME = 'rankacy';
export const GTM_ID = 'GTM-5JCTZ9P7';
