import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import { GuestGuard } from '../../auth/guard';
import { AuthLayout } from '../../layouts/auth';
import { SplashScreen } from '@rankacy/components';

const LoginPage = lazy(() => import('../../pages/auth/login'));
const RegisterPage = lazy(() => import('../../pages/auth/register'));
const ForgotPasswordPage = lazy(() => import('../../pages/auth/forgot-password'));
const NewPasswordPage = lazy(() => import('../../pages/auth/new-password'));
const RegisterCompletionPage = lazy(() => import('../../pages/auth/register-completion'));

export const authRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <AuthLayout type="image">
              <LoginPage />
            </AuthLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <AuthLayout>
              <RegisterPage />
            </AuthLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <AuthLayout>
            <ForgotPasswordPage />
          </AuthLayout>
        ),
      },
      {
        path: 'new-password/:token',
        element: (
          <AuthLayout>
            <NewPasswordPage />
          </AuthLayout>
        ),
      },
      {
        path: 'register-completion',
        element: (
          <AuthLayout>
            <RegisterCompletionPage />
          </AuthLayout>
        ),
      },
    ],
  },
];
