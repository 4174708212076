import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocales } from '@rankacy/intl';
import { v4 as uuidv4 } from 'uuid';
import {
  selectUploadingDemosWithProgress,
  useUploadDemoWithProgressMutation,
} from '@rankacy/services';

/**
 * useUploadDemos hook manages the upload process of demos.
 *
 * @returns {
 *   isSuccess: boolean - Indicates if the upload was successful.
 *   isUploading: boolean - Indicates if there are any demos currently being uploaded.
 *   uploadDemosWithProgress: Function - Initiates the demo upload process with progress tracking.
 *   numberOfFiles: number - The number of files being uploaded.
 *   uploadProgress: number - Current upload progress in percentage.
 * }
 */
export const useUploadDemos = () => {
  const { t } = useLocales();

  const [uploadDemoWithProgress, { isSuccess, isError }] = useUploadDemoWithProgressMutation();

  const { uploadingDemos } = useSelector(selectUploadingDemosWithProgress);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t('games_success_upload'), { variant: 'success', key: uuidv4() });

      dispatch({
        type: `api/invalidateTags`,
        payload: ['queue'],
      });
    }

    if (isError) {
      enqueueSnackbar(t('game_fail_upload'), { variant: 'error', key: uuidv4() });
    }
  }, [isSuccess, isError, t, dispatch]);

  const numberOfUploadingDemos = Object.keys(uploadingDemos).length;
  const isUploading = !!numberOfUploadingDemos;

  return {
    isSuccess,
    isUploading,
    uploadDemoWithProgress,
    numberOfUploadingDemos,
    uploadingDemosWithProgress: uploadingDemos,
  };
};
