import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useEventsHandler } from './use-events-handler';
import { createSocketURL } from '../utils/create-socket-url';
import { useIsTrackerActive } from './use-is-tracker-active';
import { RealTimeCommunicationMessage } from '../types';
import { hasMessageValidFormat } from '../utils/has-message-valid-format';
import { useLocalStorageSyncedState } from '@rankacy/hooks';

const SOCKET_URL = createSocketURL();
const SOCKET_STATE_KEY = 'event-tracker';

/**
 * `useEventsTracker` is a custom hook checking new messages from web socket and handle them.
 * It uses useEventsHandler hook to handle new events.
 * It uses useIsTrackerActive hook to check if tracker is active.
 * It uses useLocalStorageSyncedState hook to store last message from socket (should be distributed into all opened tabs).
 *
 * @returns {void}
 */
export const useEventsTracker = (): void => {
  const [socketLastMessage, setSocketLastMessage] =
    useLocalStorageSyncedState<string>(SOCKET_STATE_KEY);

  const { handleNewEvent } = useEventsHandler();

  const isTrackerActive = useIsTrackerActive();

  const { lastJsonMessage } = useWebSocket(SOCKET_URL, undefined, isTrackerActive);

  useEffect(() => {
    if (hasMessageValidFormat(lastJsonMessage)) {
      setSocketLastMessage(JSON.stringify(lastJsonMessage));
    }
  }, [lastJsonMessage, setSocketLastMessage]);

  useEffect(() => {
    if (socketLastMessage) {
      try {
        const parsedMessage = JSON.parse(socketLastMessage) as RealTimeCommunicationMessage;

        handleNewEvent(parsedMessage);
      } catch (error) {
        throw new Error('Error parsing socket message');
      }
    }
  }, [socketLastMessage, handleNewEvent]);
};
