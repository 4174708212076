import { PropsWithChildren } from 'react';
import { useEventsTracker } from './hooks/use-events-tracker';
import { useFeature } from '@rankacy/feature-flags';

const RealTimeCommunicationHandler = ({ children }: PropsWithChildren) => {
  useEventsTracker();

  return <>{children}</>;
};

export const RealTimeCommunicationWrapper = ({ children }: PropsWithChildren) =>
  useFeature('VITE_FEATURE_REALTIME_COMMUNICATION').on ? (
    <RealTimeCommunicationHandler>{children}</RealTimeCommunicationHandler>
  ) : (
    <>{children}</>
  );
