import { Theme } from '@mui/material/styles';
import { typographyClasses } from '@mui/material/Typography';
import { accordionClasses } from '@mui/material/Accordion';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';

const Accordion = (theme: Theme) => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.grey[800],
        border: `1px solid ${theme.palette.border}`,
        borderRadius: theme.shape.borderRadius * 2,
        '&:before': {
          backgroundColor: 'transparent',
        },
        [`&.${accordionClasses.disabled}`]: {
          backgroundColor: 'transparent',
        },
        [`&.${accordionClasses.rounded}`]: {
          borderRadius: theme.shape.borderRadius * 2,
        },
      },
    },
  },

  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: theme.shape.borderRadius * 2,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        [`&.${accordionSummaryClasses.disabled}`]: {
          opacity: 1,
          color: theme.palette.action.disabled,
          [`& .${typographyClasses.root}`]: {
            color: 'inherit',
          },
        },
      },
      expandIconWrapper: {
        color: theme.palette.grey[300],
      },
    },
  },
});

export default Accordion;
