import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';

export const HomeGetStartedCard = () => {
  const { t } = useLocales();

  const { typography } = useTheme();

  return (
    <Stack
      sx={{
        gap: 2,
        textAlign: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: pxToRem(24), md: pxToRem(42) },
          fontWeight: typography.fontWeightMedium,
        }}
        variant="h3"
      >
        <Trans
          components={{
            br: <br />,
          }}
          i18nKey="landing_page.get_started_title"
        />
      </Typography>

      <Typography
        sx={{
          fontSize: { xs: pxToRem(13), md: pxToRem(16) },
          fontFamily: typography.h1.fontFamily,
          fontWeight: typography.fontWeightLight,
        }}
      >
        {t('landing_page.get_started_subtitle')}
      </Typography>
    </Stack>
  );
};
