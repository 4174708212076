import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { EventType, RealTimeCommunicationMessage } from '../types';
// import { useGetUserNotificationsApiUserNotificationsGetQuery } from '@rankacy/services';

/**
 * `useEventsHandler` is a custom hook that handles new events in a chat application.
 * It uses several other hooks to fetch and dispatch actions based on the event type.
 * Based on the event type, it refetches the messages or notifications.
 *
 * @returns {Object} - An object with a `handleNewEvent` function.
 */
export const useEventsHandler = (): {
  handleNewEvent: (event: RealTimeCommunicationMessage) => void;
} => {
  const dispatch = useDispatch();

  // const { refetch: refetchNotifications } = useGetUserNotificationsApiUserNotificationsGetQuery({});

  const handleNewEvent = useCallback(
    (event: RealTimeCommunicationMessage) => {
      const notficationsRefetchFunction = () => {
        // refetchNotifications();

        dispatch({
          type: `api/invalidateTags`,
          payload: ['social'],
        });
      };

      const refetchFunction = {
        [EventType.Notification]: notficationsRefetchFunction,
      }[event.type];

      refetchFunction?.();
    },
    // [dispatch, refetchNotifications]
    [dispatch]
  );

  return { handleNewEvent };
};
