import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material/styles';

export const StyledNotistack = styled(MaterialDesignContent)(({ theme }) => {
  const isLight = theme.palette.mode === 'light';

  return {
    '& #notistack-snackbar': {
      ...theme.typography.subtitle2,
      padding: 0,
      flexGrow: 1,
    },
    '&.notistack-MuiContent': {
      padding: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      color: theme.palette.text.primary,
      boxShadow: theme.customShadows.z8,
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: theme.palette.background.paper,
    },
    '&.notistack-MuiContent-default': {
      padding: theme.spacing(1),
      color: isLight ? theme.palette.common.white : theme.palette.grey[800],
      backgroundColor: isLight ? theme.palette.grey[800] : theme.palette.common.white,
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.border,
      color: theme.palette.text.primary,
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.primary.contrastText,
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.primary.contrastText,
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.primary.contrastText,
    },
  };
});

type StyledIconProps = {
  color: 'info' | 'success' | 'warning' | 'error';
};

export const StyledIcon = styled('span')<StyledIconProps>(({ color, theme }) => ({
  width: 44,
  height: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette[color].main,
}));
