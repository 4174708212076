const AppBar = () => ({
  MuiAppBar: {
    defaultProps: {
      color: 'transparent',
    },

    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
});

export default AppBar;
