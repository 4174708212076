import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { useLocales } from '@rankacy/intl';
import { useBoolean } from '@rankacy/hooks';
import { SteamConnectProDialog } from './steam-connect-pro-dialog';
import { Iconify } from '@rankacy/iconify';

export const ConnectSteamAutoDownloadButton = ({ ...props }: ButtonProps) => {
  const { t } = useLocales();

  const dialog = useBoolean();

  return (
    <>
      <Button
        size="large"
        startIcon={<Iconify icon="mdi:steam" width={24} />}
        variant="contained"
        onClick={dialog.onTrue}
        {...props}
      >
        {t('set_up')}
      </Button>

      <SteamConnectProDialog open={dialog.value} onClose={dialog.onFalse} />
    </>
  );
};
