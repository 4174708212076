import { Theme, SxProps } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { paths } from '../../routes/paths';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';
import { RouterLink } from '@rankacy/routing';

type Props = {
  sx?: SxProps<Theme>;
};

const RegisterButton = ({ sx }: Props) => {
  const { t } = useLocales();

  return (
    <Button
      component={RouterLink}
      href={paths.register}
      sx={{ height: pxToRem(42), px: pxToRem(32), fontSize: pxToRem(16), ...sx }}
      variant="contained"
    >
      {t('sign_up_alpha')}
    </Button>
  );
};
export default RegisterButton;
