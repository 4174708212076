import { m } from 'framer-motion';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useResponsive } from '@rankacy/hooks';
import { useLocales } from '@rankacy/intl';
import { Box, Grid } from '@mui/material';
import { HEADER } from '../../layouts/config-layout';
import { pxToRem } from '@rankacy/theme';
import { varFade } from '@rankacy/components';

type Props = {
  title: string;
  subtitle: string;
  actions: React.ReactNode;
  textAlign?: 'left' | 'right';
};

export const HeroSection = ({ title, subtitle, actions, textAlign = 'left' }: Props) => {
  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const renderText = (
    <Stack
      sx={{
        gap: mdUp ? 6 : 3,
        textAlign,
        justifyContent: { xs: 'flex-start', md: 'center' },
        alignItems: { xs: 'flex-start', md: textAlign === 'left' ? 'flex-start' : 'flex-end' },
      }}
    >
      <Typography
        component={m.div}
        sx={{
          maxWidth: { xs: '100%', md: 591, lg: 1200 },
          whiteSpace: 'pre-line',
          fontSize: { sm: pxToRem(24), lg: pxToRem(52) },
        }}
        variant="h3"
        variants={varFade().in}
      >
        {t(title)}
      </Typography>

      <Typography
        component={m.div}
        sx={{
          width: { xs: '100%', md: 311, lg: 468 },
          color: 'text.secondary',
          fontSize: {
            sm: pxToRem(13),
            lg: pxToRem(16),
            fontWeight: 400,
            fontFamily: 'Unbounded',
          },
        }}
        variants={varFade().in}
      >
        {t(subtitle)}
      </Typography>

      {!mdUp && <m.div variants={varFade().in}>{actions}</m.div>}
    </Stack>
  );

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        maxHeight: '1110px',
        overflow: 'hidden',
      }}
    >
      <Box
        animate={{
          transform: [
            'rotate(0deg)  translateX(10px) rotate(0deg)',
            'rotate(360deg)  translateX(10px) rotate(-360deg)',
          ],
        }}
        component={m.div}
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          ...(textAlign === 'left'
            ? { right: { md: -120, xl: -162 } }
            : { left: { md: -120, xl: -162 } }),
          background: 'url(/assets/images/home/logo-levitation.webp)',
          backgroundSize: { xs: '403px', sm: '531px', md: '804px', lg: '920px', xl: '1136px ' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: {
            xs: 'bottom',
            sm: 'center',
            md: textAlign === 'left' ? 'right' : 'left',
          },
          zIndex: -1,
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          repeatType: 'loop',
          ease: 'linear',
        }}
      />
      <Container
        component={m.div}
        sx={{
          mt: { xs: pxToRem(HEADER.H_MOBILE + 60), md: pxToRem(HEADER.H_MOBILE) },
          width: 1,
          height: 1,
        }}
      >
        <Grid container sx={{ height: 1 }}>
          {textAlign === 'left' ? (
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'flex-start' }} xs={12}>
              {renderText}
            </Grid>
          ) : (
            <Grid item md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
              {renderText}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};
