import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useLocales } from '@rankacy/intl';
import { RouterLink } from '@rankacy/routing';
import { paths } from '../../routes/paths';
import { pxToRem } from '@rankacy/theme';

const BG_IMAGE = '/assets/images/home/join-the-community.webp';

export const HomeJoinCommunityCard = () => {
  const { t } = useLocales();

  const { typography } = useTheme();

  return (
    <Card
      sx={{
        backgroundImage: `url(${BG_IMAGE})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
        height: { xs: 511, lg: 400 },
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          height: '100%',
          pl: 5,
        }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            maxWidth: 560,
            ...typography.h2,
            fontSize: { xs: pxToRem(32), lg: pxToRem(52) },
            fontWeight: typography.fontWeightMedium,
          }}
        >
          {t('landing_page.join_community_title')}
        </Typography>

        <Button
          component={RouterLink}
          href={paths.register}
          sx={{ height: pxToRem(42), px: pxToRem(32), fontSize: pxToRem(16), mt: 2 }}
          variant="outlined"
        >
          {t('sign_up_today')}
        </Button>
      </CardContent>
    </Card>
  );
};
