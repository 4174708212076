export const buildSteamConnectReturnUrl = (returnTo: string): string => {
  const loginUrlParams = new URLSearchParams();
  loginUrlParams.append('openid.ns', 'http://specs.openid.net/auth/2.0');
  loginUrlParams.append('openid.mode', 'checkid_setup');
  loginUrlParams.append('openid.return_to', returnTo);
  loginUrlParams.append('openid.realm', `${window.location.protocol}//${window.location.host}`);
  loginUrlParams.append('openid.identity', 'http://specs.openid.net/auth/2.0/identifier_select');
  loginUrlParams.append('openid.claimed_id', 'http://specs.openid.net/auth/2.0/identifier_select');

  const steamLoginUrl = new URL('https://steamcommunity.com/openid/login');
  steamLoginUrl.search = loginUrlParams.toString();

  return steamLoginUrl.toString();
};
