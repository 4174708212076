import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useResponsive } from '@rankacy/hooks';
import { NAV } from '../config-layout';
import { AccountPopover, NavToggleButton } from '../_common';
import { hideScroll } from '@rankacy/theme';
import { Logo, NavSectionMini, NavSectionProps } from '@rankacy/components';
import { paths } from '../../routes/paths';

type Props = {
  navData: NavSectionProps['data'];
};

const NavMini = ({ navData }: Props) => {
  const mdUp = useResponsive('up', 'md');

  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: mdUp ? 48 : 80,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `solid 1px ${theme.palette.border}`,
          ...hideScroll.x,
        }}
      >
        <Logo
          logoHeight={33}
          sx={{ mx: 'auto', my: 2, mt: lgUp ? 4 : 3 }}
          to={paths.dashboard.root}
          variant="small"
        />

        <Stack sx={{ mx: 'auto', my: 2 }}>
          <AccountPopover type="nav-vertical" />
        </Stack>

        <NavSectionMini data={navData} />
      </Stack>
    </Box>
  );
};

export default NavMini;
