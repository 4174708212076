import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useOffSetTop, useResponsive } from '@rankacy/hooks';
import { SvgColor } from '@rankacy/components';
import { useSettingsContext } from '@rankacy/settings';
import { useFeature } from '@rankacy/feature-flags';
import { HEADER, NAV } from '../config-layout';
import { AccountPopover } from '../_common';

type Props = {
  onOpenNav?: VoidFunction;
  customActions?: React.ReactNode;
};

const Header = ({ onOpenNav, customActions }: Props) => {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset;

  const globalAnnouncementEnabled = useFeature('VITE_FEATURE_GLOBAL_ANNOUNCEMENT').on;

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton sx={{ color: 'text.secondary' }} onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Stack
        alignItems="center"
        direction="row"
        flexGrow={1}
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}

        {customActions}

        {/* <SettingsButton /> */}

        <AccountPopover type="header" />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        ...(globalAnnouncementEnabled && { top: HEADER.H_ANNOUNCEMENT }),
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
