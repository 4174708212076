import Box from '@mui/material/Box';
import { PropsWithChildren } from 'react';
import Footer from './footer';
import Header from './header';
import BackgroundPattern from '../_common/background-pattern';

export const MainLayout = ({ children }: PropsWithChildren) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
    <Header />

    <BackgroundPattern type="half" />

    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      {children}
    </Box>

    <Footer />
  </Box>
);
