import { Theme } from '@mui/material/styles';
import { menuItem } from '../../css';

const Menu = (theme: Theme) => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        ...menuItem(theme),
      },
    },
  },
});

export default Menu;
