import { useEffect } from 'react';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useBoolean } from '@rankacy/hooks';
import { Logo, Scrollbar, SvgColor } from '@rankacy/components';
import { LoginButton, RegisterButton } from '../../../../layouts/_common';
import { Box, Button, Stack } from '@mui/material';
import { useAuthContext } from '../../../../auth';
import { paths } from '../../../../routes/paths';
import { NavProps } from '../types';
import NavList from './nav-list';
import { useLocales } from '@rankacy/intl';
import { RouterLink, usePathname } from '@rankacy/routing';

const NavMobile = ({ data }: NavProps) => {
  const { t } = useLocales();

  const pathname = usePathname();

  const { authenticated } = useAuthContext();

  const nav = useBoolean();

  useEffect(() => {
    if (nav.value) {
      nav.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <IconButton
        sx={{
          ml: 'auto',
          color: 'text.secondary',
        }}
        onClick={nav.onTrue}
      >
        <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
      </IconButton>

      <Drawer
        PaperProps={{
          sx: {
            pb: 5,
            width: 260,
          },
        }}
        anchor="right"
        open={nav.value}
        onClose={nav.onFalse}
      >
        <Scrollbar sx={{ textAlign: 'center' }}>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List disablePadding component="nav">
            {data.map((link) => (
              <NavList item={link} key={link.title} />
            ))}
          </List>
        </Scrollbar>

        <Box sx={{ flexGrow: 1 }} />

        <Stack sx={{ gap: 2, mx: 2 }}>
          {authenticated ? (
            <Button
              LinkComponent={RouterLink}
              href={paths.dashboard.root}
              size="large"
              variant="contained"
            >
              {t('go_to_rankacy')}
            </Button>
          ) : (
            <>
              <LoginButton />
              <RegisterButton />
            </>
          )}
        </Stack>
      </Drawer>
    </>
  );
};

export default NavMobile;
