import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import { NavItemProps, NavConfigProps } from '../types';
import { StyledItem, StyledIcon } from './styles';
import { Iconify } from '@rankacy/iconify';
import { RouterLink } from '@rankacy/routing';

type Props = NavItemProps & {
  config: NavConfigProps;
};

export const NavItem = forwardRef<HTMLDivElement, Props>(
  ({ item, depth, open, active, externalLink, config, ...other }, ref) => {
    const theme = useTheme();

    const { title, path, icon, children, disabled, caption } = item;

    const subItem = depth !== 1;

    const renderContent = (
      <StyledItem
        disableGutters
        active={active}
        config={config}
        depth={depth}
        disabled={disabled}
        open={open}
        ref={ref}
        {...other}
      >
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {!(config.hiddenLabel && !subItem) && (
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              noWrap: true,
              fontSize: 10,
              lineHeight: '16px',
              textAlign: 'center',
              fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
              ...(subItem && {
                textAlign: 'unset',
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body2.lineHeight,
                fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
              }),
            }}
            sx={{
              width: 1,
              flex: 'unset',
              ...(!subItem && {
                px: 0.5,
                mt: 0.5,
              }),
            }}
          />
        )}

        {caption && (
          <Tooltip arrow placement="right" title={caption}>
            <Iconify
              icon="eva:info-outline"
              sx={{
                color: 'text.disabled',
                ...(!subItem && {
                  top: 11,
                  left: 6,
                  position: 'absolute',
                }),
              }}
              width={16}
            />
          </Tooltip>
        )}

        {!!children && (
          <Iconify
            icon="eva:arrow-ios-forward-fill"
            sx={{
              top: 11,
              right: 6,
              position: 'absolute',
            }}
            width={16}
          />
        )}
      </StyledItem>
    );

    // External link
    if (externalLink)
      return (
        <Link
          href={path}
          rel="noopener"
          sx={{
            width: 1,
            ...(disabled && {
              cursor: 'default',
            }),
          }}
          target="_blank"
          underline="none"
        >
          {renderContent}
        </Link>
      );

    // Default
    return (
      <Link
        component={RouterLink}
        href={path}
        sx={{
          width: 1,
          ...(disabled && {
            cursor: 'default',
          }),
        }}
        underline="none"
      >
        {renderContent}
      </Link>
    );
  }
);
