import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUserDemoProcessingApiGamesProcessingGetQuery } from '@rankacy/services';

const _10_SECONDS = 10_000;

/**
 * useGetTmpDemos hook manages temporary demo objects.
 *
 * @param {boolean} isSuccessDemoUpload - Indicates whether the demo upload was successful.
 * @returns {
 *   failedCount: number - The count of demos that failed to upload.
 *   processingCount: number - The count of demos that are currently being processed or queued.
 *   isTmpDemosFetching: boolean - Indicates if the fetching of temporary demos is ongoing.
 *   isDoneProcessing: boolean - Indicates if the demos processing is complete.
 * }
 */
export const useGetTmpDemos = (isSuccessDemoUpload: boolean) => {
  const [checkingForChanges, setCheckingForChanges] = useState(false);
  const [remainingProcessingSeconds, setRemainingProcessingSeconds] = useState(0);

  const dispatch = useDispatch();

  const { data, isFetching, fulfilledTimeStamp } = useUserDemoProcessingApiGamesProcessingGetQuery(
    undefined,
    {
      pollingInterval: checkingForChanges ? _10_SECONDS : undefined,
    }
  );

  const [lastProcessingCount, setLastProcessingCount] = useState(0);

  const processingCount = data?.PROCESSING || 0;
  const failedCount = data?.FAILED || 0;
  const queuedCount = data?.QUEUED || 0;
  const processingTimeEstimation = data?.processing_time_seconds;

  useEffect(() => {
    if (processingTimeEstimation) {
      setRemainingProcessingSeconds(processingTimeEstimation);
    }
  }, [processingTimeEstimation, fulfilledTimeStamp]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (remainingProcessingSeconds > 0) {
      const interval = setInterval(() => {
        setRemainingProcessingSeconds((prev) => Math.max(prev - 1, 0));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [remainingProcessingSeconds]);

  // Side effect to determine when to start or stop checking for changes in demos' status.
  useEffect(() => {
    if (isSuccessDemoUpload || processingCount > 0 || queuedCount > 0) {
      setCheckingForChanges(true);
    } else if (processingCount < lastProcessingCount || queuedCount < 1) {
      if (checkingForChanges) {
        dispatch({
          type: `api/invalidateTags`,
          payload: ['matches'],
        });
      }

      setLastProcessingCount(processingCount);

      setCheckingForChanges(false);
    }
  }, [
    isSuccessDemoUpload,
    processingCount,
    queuedCount,
    checkingForChanges,
    dispatch,
    lastProcessingCount,
  ]);

  return {
    failedCount,
    processingCount,
    queuedCount,
    isTmpDemosFetching: isFetching,
    isDoneProcessing: processingCount === 0,
    remainingProcessingSeconds,
  };
};
