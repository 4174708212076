import { paths } from '../../routes/paths';

export const FEATURE_CARDS = (mdDown = false) => [
  {
    title: 'landing_page.quality_title',
    subTitle: 'landing_page.quality_subtitle',
    image: `/assets/images/home/feature-dron${mdDown ? '-mobile' : ''}.webp`,
  },
  {
    title: 'landing_page.cheater_title',
    subTitle: 'landing_page.cheater_subtitle',
    image: `/assets/images/home/feature-camera${mdDown ? '-mobile' : ''}.webp`,
  },
  {
    title: 'landing_page.instant_highlights_title',
    subTitle: 'landing_page.instant_highlights_subtitle',
    image: `/assets/images/home/feature-socials${mdDown ? '-mobile' : ''}.webp`,
  },
];

export const CAROUSEL_CARDS = (mdDown = false) => [
  {
    image: '/assets/background/landing-page-all-in-one.webp',
    title: 'landing_page.all_in_one_title',
    subTitle: 'landing_page.all_in_one_subtitle',
    color: mdDown ? 'text.primary' : 'grey.800',
  },
  {
    image: '/assets/background/landing-page-in-depth-intel.webp',
    title: 'landing_page.in_depth_title',
    subTitle: 'landing_page.in_depth_subtitle',
    color: 'text.primary',
  },
  {
    image: '/assets/background/landing-page-real-time-coaching.webp',
    title: 'landing_page.real_time_title',
    subTitle: 'landing_page.real_time_subtitle',
    color: 'text.primary',
  },
];

export const LEVEL_UP_CARD = [
  {
    image: '/assets/background/level-up.webp',
    title: 'landing_page.level_up_title',
    subTitle: 'landing_page.level_up_subtitle',
    action: {
      label: 'try_for_free',
      href: paths.register,
    },
  },
];
