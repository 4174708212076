import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { useResponsive } from '@rankacy/hooks';

import { Divider, Link, Typography } from '@mui/material';
import { useLocales } from '@rankacy/intl';
import { paths } from '../../routes/paths';
import { SteamConnectWidget } from '../../sections/steam/steam-connect-widget';
import { useAuthContext } from '../../auth';
import { useFeature } from '@rankacy/feature-flags';
import { AccountPopover, NavToggleButton } from '../_common';
import { NAV } from '../config-layout';
import { Logo, NavSectionProps, NavSectionVertical, Scrollbar } from '@rankacy/components';
import { RouterLink, usePathname } from '@rankacy/routing';
import { UploadDemoButton } from '../../sections/match/list/upload-demo-button';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
  navData: NavSectionProps['data'];
};

const NavVertical = ({ openNav, onCloseNav, navData }: Props) => {
  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const { user } = useAuthContext();

  const { t } = useLocales();

  const globalAnnouncementEnabled = useFeature('VITE_FEATURE_GLOBAL_ANNOUNCEMENT').on;

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack>
        <Logo
          logoHeight={33}
          sx={{ mt: lgUp ? 4 : 3, mb: 3, mx: 'auto' }}
          to={paths.dashboard.root}
        />
      </Stack>

      {lgUp && (
        <>
          <Stack sx={{ w: 1, m: 2, mb: 1, gap: 0.5, alignItems: 'center', flexDirection: 'row' }}>
            <AccountPopover type="nav-vertical" />

            <Stack sx={{ overflow: 'hidden' }}>
              <Typography noWrap variant="subtitle2">
                {user?.username}
              </Typography>

              <Typography noWrap sx={{ color: 'text.secondary' }} variant="body2">
                {user?.email}
              </Typography>
            </Stack>
          </Stack>

          <Divider sx={{ m: 2 }} />
        </>
      )}

      <NavSectionVertical data={navData} />

      <Box sx={{ flexGrow: 1 }} />

      <SteamConnectWidget />

      <UploadDemoButton buttonText={t('upload_demo')} size="medium" sx={{ m: 2 }} />

      <Stack
        divider={
          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: ({ palette }) => palette.grey[600] }}
          />
        }
        sx={{ flexDirection: 'row', gap: 1, justifyContent: 'center', pb: 1 }}
      >
        <Link
          color="text.secondary"
          component={RouterLink}
          href={paths.termsAndConditions}
          sx={{ fontWeight: 700 }}
          variant="caption"
        >
          {t('terms_conditions')}
        </Link>

        <Link
          color="text.secondary"
          component={RouterLink}
          href={paths.privacyPolicy}
          sx={{ fontWeight: 700 }}
          variant="caption"
        >
          {t('privacy_policy')}
        </Link>
      </Stack>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `solid 1px ${theme.palette.border}`,
            pb: globalAnnouncementEnabled ? 5 : 0,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          PaperProps={{
            sx: {
              pt: 1,
              width: NAV.W_VERTICAL,
            },
          }}
          open={openNav}
          onClose={onCloseNav}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

export default NavVertical;
