import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocales } from '@rankacy/intl';
import { ConnectSteamAccountButton } from './connect-steam-account-button';
import { useSteamConnectionAndGamesStatus } from './hooks';
import { ConnectSteamAutoDownloadButton } from './connect-steam-autodownload-button';
import { Image } from '@rankacy/components';

export const SteamConnectWidget = () => {
  const { t } = useLocales();

  const { isConnectedToSteam, autoDownloadCodesProvided, isLoading } =
    useSteamConnectionAndGamesStatus();

  if (!isLoading && !isConnectedToSteam) {
    return (
      <Stack sx={{ alignItems: 'center', textAlign: 'center', p: 2, gap: 2.5, mb: 2 }}>
        <Image
          src="/assets/images/highlights/steam_connect.webp"
          sx={{
            width: 140,
            mb: -3.5,
          }}
        />

        <Typography variant="h6">{t('steam_connect')}</Typography>

        <ConnectSteamAccountButton smallVersionWithIcon />
      </Stack>
    );
  }

  if (!isLoading && isConnectedToSteam && !autoDownloadCodesProvided) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          textAlign: 'center',
          p: 2,
          gap: 2.5,
          mb: 2,
        }}
      >
        <Image
          src="/assets/images/highlights/steam_connect.webp"
          sx={{
            width: 140,
            mb: -3.5,
          }}
        />

        <Typography variant="h6">{t('auto_download_steam')}</Typography>

        <ConnectSteamAutoDownloadButton />
      </Stack>
    );
  }

  return null;
};
