import { IconButton, Stack, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocales } from '@rankacy/intl';
import { useBoolean } from '@rankacy/hooks';
import { useSelector } from 'react-redux';
import { Indicator } from './styles';
import { BackgroundTaskItem } from './background-task-item';
import { selectUploadingDemosWithProgress } from '@rankacy/services';
import { Iconify } from '@rankacy/iconify';

type ITaskItem = {
  progress?: number;
  label: string;
};

type Props = {
  activeBackgroundTasks?: number;
  title?: string;
  failed?: ITaskItem;
  queued?: ITaskItem;
  tasksWithProgressComponent?: ReactElement | null;
  onClearFailed?: () => void;
  alert?: ReactElement | null;
};

export const BackgroundTasksIndicator = ({
  activeBackgroundTasks,
  queued,
  failed,
  title,
  tasksWithProgressComponent,
  alert,
  onClearFailed,
}: Props) => {
  const { t } = useLocales();

  const [hiddenByUser, setHiddenByUser] = useState(false);

  const { value: opened, onToggle } = useBoolean(true);

  const someTasksWithoutFailed = queued || tasksWithProgressComponent;

  const someTaskIncludingFailed = someTasksWithoutFailed || failed;

  const { uploadingDemos } = useSelector(selectUploadingDemosWithProgress);

  useEffect(() => {
    if (Object.keys(uploadingDemos).length) {
      setHiddenByUser(false);
    }
  }, [uploadingDemos]);

  if (hiddenByUser || !someTaskIncludingFailed) return null;

  const renderHead = (
    <Stack
      sx={{ gap: 1, flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}
      onClick={onToggle}
    >
      <IconButton
        sx={{ p: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          onToggle();
        }}
      >
        <Iconify
          color="text.primary"
          icon={opened ? 'mdi:chevron-down' : 'mdi:chevron-up'}
          width={24}
        />
      </IconButton>

      <Typography
        sx={{
          fontFamily: ({ typography }) => typography.button.fontFamily,
          flexGrow: 1,
          fontWeight: 400,
        }}
        variant="subtitle2"
      >
        {title || t('background_tasks', { count: activeBackgroundTasks })}
      </Typography>

      <IconButton
        sx={{ p: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          setHiddenByUser(true);
        }}
      >
        <Iconify color="text.primary" icon="mdi:close" width={18} />
      </IconButton>
    </Stack>
  );

  return (
    <Indicator>
      {renderHead}

      <Stack sx={{ gap: 1, mt: opened ? 1 : 0 }}>
        {opened && (
          <>
            {alert}

            {someTasksWithoutFailed && (
              <Typography color="text.secondary" variant="caption">
                {t('new')}
              </Typography>
            )}

            {tasksWithProgressComponent}

            {queued && <BackgroundTaskItem label={queued.label} type="queued" />}

            {failed && (
              <>
                <Typography color="text.secondary" sx={{ mt: 1 }} variant="caption">
                  {t('failed')}
                </Typography>

                <BackgroundTaskItem
                  label={failed.label}
                  type="failed"
                  onClearFailed={onClearFailed}
                />
              </>
            )}
          </>
        )}
      </Stack>
    </Indicator>
  );
};
