import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, BoxProps } from '@mui/material';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  to?: string;
  variant?: 'text' | 'small' | 'white';
  logoHeight?: number;
}

export const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, to = '/', sx, variant = 'text', logoHeight }, _) => {
    const logo = (
      <Box sx={{ ...sx }}>
        <Box
          component="img"
          src={`/logo/${getLogo(variant)}`}
          sx={{
            height: variant === 'small' ? logoHeight ?? 40 : logoHeight ?? 33,
            cursor: disabledLink ? 'default' : 'pointer',
          }}
        />
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} sx={{ display: 'contents' }} to={to}>
        {logo}
      </Link>
    );
  }
);

const TEXT_LOGO = 'logo-full.svg';

const SMALL_LOGO = 'logo-small.svg';

const WHITE_LOGO = 'logo-white.svg';

const getLogo = (variant: 'text' | 'small' | 'white') => {
  switch (variant) {
    case 'text':
      return TEXT_LOGO;
    case 'small':
      return SMALL_LOGO;
    case 'white':
      return WHITE_LOGO;
    default:
      return TEXT_LOGO;
  }
};
