import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useLocales } from '@rankacy/intl';
import { useBoolean } from '@rankacy/hooks';
import { useUploadDemos } from '../../../sections/background-tasks/hooks';
import { DemoUploadDialog } from '../../../sections/demo-upload';
import { Iconify } from '@rankacy/iconify';
import { CustomFile } from '@rankacy/components';
import {
  handleErrorMessage,
  useLazyGetDemoByHashApiGamesDemoHashGetQuery,
} from '@rankacy/services';
import { customSha256 } from '@rankacy/utils';
import { useDispatch } from 'react-redux';

type Props = ButtonProps & {
  buttonText?: string;
  mini?: boolean;
};

export const UploadDemoButton = ({ buttonText, mini, ...props }: Props) => {
  const { t } = useLocales();

  const upload = useBoolean();

  const dispatch = useDispatch();

  const { isUploading, uploadDemoWithProgress } = useUploadDemos();

  const [isCheckingExisting, setIsCheckingExisting] = useState(false);

  const [checkIfDemoExists] = useLazyGetDemoByHashApiGamesDemoHashGetQuery();

  const handleUpload = async (demos: CustomFile[]) => {
    setIsCheckingExisting(true);

    const uploadTasks = demos.map(async (demo) => {
      const arrayBuffer = new Uint8Array(await demo.arrayBuffer());
      const calcedHash = customSha256(arrayBuffer);

      try {
        const exists = await checkIfDemoExists({
          hash: calcedHash,
          alias: demo.aliasName ?? demo.name,
        }).unwrap();

        if (exists) {
          dispatch({
            type: `api/invalidateTags`,
            payload: ['matches'],
          });

          enqueueSnackbar(t('game_success_upload'), { variant: 'success' });

          return;
        }
      } catch (e) {
        enqueueSnackbar(handleErrorMessage(e), { variant: 'error' });
      }

      const formData = new FormData();
      formData.append('file', demo);
      formData.append('alias', demo?.aliasName ?? demo.name);

      uploadDemoWithProgress({ hash: calcedHash, data: formData });

      enqueueSnackbar(t('uploading_message'), {
        variant: 'info',
      });
    });

    await Promise.all(uploadTasks);
    setIsCheckingExisting(false);
  };

  return (
    <>
      <LoadingButton
        disabled={isCheckingExisting || isUploading}
        loading={isCheckingExisting || isUploading}
        loadingPosition="start"
        size="large"
        startIcon={<Iconify icon="mdi:tray-arrow-up" />}
        variant="contained"
        onClick={upload.onTrue}
        {...props}
      >
        {(isCheckingExisting && t('checking')) ||
          (isUploading && t('uploading')) ||
          buttonText ||
          t('upload')}
      </LoadingButton>

      <DemoUploadDialog
        open={upload.value}
        title={t('upload')}
        onClose={upload.onFalse}
        onUpload={handleUpload}
      />
    </>
  );
};
