import { Box, Stack, styled } from '@mui/material';

export const INDICATOR_POSITION = 40;

export const Indicator = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: INDICATOR_POSITION,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.border}`,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: 'none',
  width: 320,
  zIndex: theme.zIndex.snackbar,
  [theme.breakpoints.down('sm')]: {
    left: '50%',
    transform: 'translateX(-50%)',
    right: 'auto',
    width: '100%',
  },
}));

type TaskItemProps = {
  variant: 'info' | 'success' | 'warning' | 'error';
  disabled?: boolean;
};

export const TaskItem = styled(Stack)<TaskItemProps>(({ variant, disabled, theme }) => ({
  flexDirection: 'row',
  gap: 1,
  backgroundColor: theme.palette[variant].light,
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderRadius: theme.shape.borderRadius * 2,
  color: theme.palette.primary.contrastText,
  ...(variant === 'info' && {
    backgroundColor: theme.palette.border,
    color: theme.palette.text.primary,
  }),
  ...(disabled && {
    backgroundColor: theme.palette.grey[700],
  }),
}));
