import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetHighlightProcessingApiHighlightsProcessingGetQuery } from '@rankacy/services';

const _10_SECONDS = 10_000;

/**
 * useGetGeneratingHighlights hook manages the tracking of game highlights being generated.
 *
 * This hook uses polling to periodically check the status of game highlights generation.
 * It updates the state to reflect whether there are any highlights currently being generated
 * and invalidates relevant API cache if the count of generating highlights decreases.
 *
 * @returns {
 *   generatingCount: number - The count of highlights that are currently being generated.
 *   isDoneGenerating: boolean - Indicates if the highlights generation process is complete.
 *   remainingProcessingSeconds: number - The estimated time remaining for processing in seconds.
 * }
 */
export const useGetGeneratingHighlights = () => {
  const [checkingForChanges, setCheckingForChanges] = useState(false);
  const [remainingGeneratingSeconds, setRemainingGeneratingSeconds] = useState(0);

  const [lastCount, setLastCount] = useState(0);

  const dispatch = useDispatch();

  const { data, fulfilledTimeStamp } = useGetHighlightProcessingApiHighlightsProcessingGetQuery(
    undefined,
    {
      pollingInterval: checkingForChanges ? _10_SECONDS : undefined,
    }
  );

  const generatingCount = data?.count || 0;
  const generatingTimeEstimation = data?.process_time_seconds;

  useEffect(() => {
    if (generatingTimeEstimation) {
      setRemainingGeneratingSeconds(generatingTimeEstimation);
    }
  }, [generatingTimeEstimation, fulfilledTimeStamp]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (remainingGeneratingSeconds > 0) {
      const interval = setInterval(() => {
        setRemainingGeneratingSeconds((prev) => Math.max(prev - 1, 0));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [remainingGeneratingSeconds]);

  useEffect(() => {
    if (generatingCount > 0) {
      setCheckingForChanges(true);
    } else {
      setCheckingForChanges(false);
    }

    if (generatingCount < lastCount) {
      dispatch({
        type: `api/invalidateTags`,
        payload: ['highlights'],
      });
    }

    setLastCount(generatingCount);
  }, [generatingCount, lastCount, setCheckingForChanges, dispatch]);

  return {
    generatingCount,
    isDoneGenerating: generatingCount === 0,
    remainingGeneratingSeconds,
  };
};
