import Stack from '@mui/material/Stack';
import { NavProps } from '../types';
import NavList from './nav-list';

const NavDesktop = ({ offsetTop, data }: NavProps) => (
  <Stack component="nav" direction="row" spacing={5} sx={{ mr: 2.5, height: 1 }}>
    {data.map((link) => (
      <NavList item={link} key={link.title} offsetTop={offsetTop} />
    ))}
  </Stack>
);

export default NavDesktop;
