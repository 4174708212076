import { useState, useEffect, useCallback } from 'react';
import Collapse from '@mui/material/Collapse';
import { NavListProps, NavConfigProps } from '../types';
import { useActiveLink, usePathname } from '@rankacy/routing';
import { NavItem } from './nav-item';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
};

export const NavList = ({ data, depth, hasChild, config }: NavListRootProps) => {
  const pathname = usePathname();

  const active = useActiveLink(data.path, hasChild);

  const externalLink = data.path.includes('http');

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <NavItem
        active={active}
        config={config}
        depth={depth}
        externalLink={externalLink}
        item={data}
        open={open}
        onClick={handleToggle}
      />

      {hasChild && (
        <Collapse unmountOnExit in={open}>
          <NavSubList config={config} data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
};

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
};

const NavSubList = ({ data, depth, config }: NavListSubProps) => (
  <>
    {data.map((list) => (
      <NavList
        config={config}
        data={list}
        depth={depth + 1}
        hasChild={!!list.children}
        key={list.title + list.path}
      />
    ))}
  </>
);
