import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { localStorageGetItem } from '@rankacy/utils';
import { useDispatch } from 'react-redux';
import { allLangs, defaultLang } from './config';
import { Namespaces, setLanguage } from '.';

export const useLocales = (ns?: Namespaces) => {
  const { t } = useTranslation(ns);

  const dispatch = useDispatch();

  const langStorage = localStorageGetItem('i18nextLng');

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const onChangeLang = useCallback(
    async (newlang: string) => {
      setLanguage(newlang);

      dispatch({
        type: `api/invalidateTags`,
        payload: ['translations'],
      });
    },
    [dispatch]
  );

  return {
    allLangs,
    t,
    currentLang,
    onChangeLang,
  };
};
