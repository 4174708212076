import { memo } from 'react';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { Box, Divider } from '@mui/material';
import { NavSectionProps, NavListProps, NavConfigProps } from '../types';
import { navVerticalConfig } from '../config';
import { NavList } from './nav-list';

const NavSectionVertical = ({ data, config, sx, ...other }: NavSectionProps) => (
  <Stack sx={sx} {...other}>
    {data.map((group, index) => (
      <Box key={group.subheader || index}>
        <Group config={navVerticalConfig(config)} items={group.items} subheader={group.subheader} />
        {data.length !== index + 1 && <Divider sx={{ my: 2 }} variant="middle" />}
      </Box>
    ))}
  </Stack>
);

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

type GroupProps = {
  subheader: string;
  items: NavListProps[];
  config: NavConfigProps;
};

const Group = ({ subheader, items, config }: GroupProps) => {
  const renderContent = items.map((list) => (
    <NavList
      config={config}
      data={list}
      depth={1}
      hasChild={!!list.children}
      key={list.title + list.path}
    />
  ));

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? <>{renderContent}</> : renderContent}
    </List>
  );
};
