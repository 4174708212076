const SvgIcon = () => ({
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeLarge: {
        width: 32,
        height: 32,
        fontSize: 'inherit',
      },
    },
  },
});

export default SvgIcon;
