import i18next from 'i18next';
import { ResponseErrorWithMessage } from '../types';
import { API_ERRORS_PREFIX, apiErrors } from '@rankacy/intl';

/**
 * Source (RTK Query docs): https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
 * Type predicate to narrow an unknown error to an object with a string 'data' and 'error' property
 */
export const isErrorWithMessage = (error: unknown): error is ResponseErrorWithMessage =>
  typeof error === 'object' &&
  error != null &&
  'data' in error &&
  typeof (error as any).data === 'object' &&
  'detail' in (error as any).data;

/**
 * Handles errors with a single detailed message, translating it if it starts with a known prefix.
 * @param error The error object to handle.
 * @returns The translated or raw error message.
 */
export const handleErrorMessage = (error: unknown) => {
  if (isErrorWithMessage(error)) {
    return error.data.detail.startsWith(API_ERRORS_PREFIX)
      ? i18next.t(apiErrors(error.data.detail))
      : error.data.detail;
  }

  return i18next.t('general_error_message');
};
