import { PropsWithChildren, useRef } from 'react';
import { SnackbarProvider as NotistackProvider, closeSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import { StyledIcon, StyledNotistack } from './styles';
import { Iconify } from '@rankacy/iconify';

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const notistackRef = useRef<any>(null);

  return (
    <NotistackProvider
      preventDuplicate
      Components={{
        default: StyledNotistack,
        info: StyledNotistack,
        success: StyledNotistack,
        warning: StyledNotistack,
        error: StyledNotistack,
      }}
      action={(snackbarId) => (
        <IconButton
          size="small"
          sx={{ p: 0.5, color: 'inherit' }}
          onClick={() => closeSnackbar(snackbarId)}
        >
          <Iconify icon="mdi:close" width={24} />
        </IconButton>
      )}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      iconVariant={{
        info: (
          <StyledIcon color="info">
            <Iconify icon="mdi:information" width={24} />
          </StyledIcon>
        ),
        success: (
          <StyledIcon color="success">
            <Iconify icon="mdi:check-circle" width={24} />
          </StyledIcon>
        ),
        warning: (
          <StyledIcon color="warning">
            <Iconify icon="mdi:alert" width={24} />
          </StyledIcon>
        ),
        error: (
          <StyledIcon color="error">
            <Iconify icon="mdi:alert-circle" width={24} />
          </StyledIcon>
        ),
      }}
      maxSnack={5}
      ref={notistackRef}
      variant="info"
    >
      {children}
    </NotistackProvider>
  );
};
