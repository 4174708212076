import { Theme, alpha } from '@mui/material/styles';

const Paper = (theme: Theme) => ({
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
      outlined: {
        borderColor: alpha(theme.palette.grey[500], 0.16),
      },
    },
  },
});

export default Paper;
