import { PropsWithChildren } from 'react';
import { FeatureFlagsType } from './types';
import { useFeature } from './hooks';

export const FeatureEnabledWrapper = ({
  children,
  feature,
}: PropsWithChildren & { feature: keyof FeatureFlagsType }) =>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  useFeature(feature).on ? <>{children}</> : null;
