import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useOffSetTop } from '@rankacy/hooks';
import { bgBlur } from '@rankacy/theme';
import { Logo } from '@rankacy/components';
import { HeaderShadow } from './header-shadow';
import { HEADER } from '../config';

export const HeaderSimple = () => {
  const theme = useTheme();

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Logo variant="small" />
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
};
