import { Navigate } from 'react-router';

const createRedirectRoute = (from: string, to: string) => ({
  path: `auth/jwt/${from}`,
  element: <Navigate replace to={to} />,
});

export const redirectRoutes = [
  createRedirectRoute('login', '/login'),
  createRedirectRoute('register', '/register'),
  createRedirectRoute('forgot-password', '/forgot-password'),
  createRedirectRoute('new-password/:token', '/new-password/:token'),
  createRedirectRoute('register-completion', '/register-completion'),
];
