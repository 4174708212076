import { ListItem, TextField } from '@mui/material';
import { useState, ChangeEvent } from 'react';
import { CustomFile } from './types';
import { fileData } from '../file-thumbnail';

interface Props {
  file: File | string;
  onRename?: (file: CustomFile | string, newName: string) => void;
}

export const FileNameSetter = ({ file, onRename }: Props) => {
  const { name = '' } = fileData(file);
  const [fileName, setFileName] = useState(name);
  const [validationError, setValidationError] = useState('');

  const handleFileRename = (e: ChangeEvent<HTMLInputElement>) => {
    const newFileName = e.target.value;
    setFileName(newFileName);

    if (newFileName.length === 0) {
      setValidationError('File name is required.');
    } else {
      setValidationError('');
    }

    onRename?.(file, newFileName);
  };

  return (
    <ListItem>
      <TextField
        fullWidth
        error={!!validationError}
        helperText={validationError}
        label="Demo name"
        value={fileName}
        onChange={handleFileRename}
      />
    </ListItem>
  );
};
