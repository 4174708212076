import { FC, PropsWithChildren, useMemo } from 'react';
import { FeatureFlagsContext } from './feature-flags-context';
import { FeatureFlagsType } from '../types';

export const FeatureFlagsProvider: FC<PropsWithChildren<{ features: FeatureFlagsType }>> = ({
  features,
  children,
}) => {
  const memoizedValue = useMemo(() => ({ features }), [features]);

  return (
    <FeatureFlagsContext.Provider value={memoizedValue}>{children}</FeatureFlagsContext.Provider>
  );
};
