import { PropsWithChildren, useCallback, useEffect } from 'react';
import { paths } from '../../routes/paths';
import { useBoolean } from '@rankacy/hooks';
import { useAuthContext } from '../hooks';
import { useRouter } from '@rankacy/routing';
import { useLocation } from 'react-router';

const GuestGuard = ({ children }: PropsWithChildren) => {
  const router = useRouter();

  const { authenticated } = useAuthContext();

  const routerLocation = useLocation();

  const showContent = useBoolean(false);

  const check = useCallback(() => {
    if (authenticated && !routerLocation.state?.disableGuestGuard) {
      router.replace(paths.dashboard.root);
    }

    showContent.onTrue();
  }, [authenticated, router, showContent, routerLocation.state?.disableGuestGuard]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{showContent.value && children}</>;
};

export default GuestGuard;
