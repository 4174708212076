import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { pxToRem } from '@rankacy/theme';
import { useResponsive } from '@rankacy/hooks';
import { ConditionalWrap } from '@rankacy/components';

export const HomeGetStartedSteps = () => {
  const { typography, palette } = useTheme();

  const [progress, setProgress] = useState(0);

  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    let animationFrameId: number;

    const maxProgressByCard = hoveredCard! * 90;

    if (hoveredCard !== null && maxProgressByCard) {
      animationFrameId = requestAnimationFrame(() =>
        setProgress((prev) => Math.min(prev + 10, maxProgressByCard))
      );
    } else if (hoveredCard === null && progress > 0) {
      animationFrameId = requestAnimationFrame(() => setProgress(0));
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [hoveredCard, progress]);

  const handleMouseEnter = (step: number) => () => {
    setHoveredCard(step);
    setProgress(0);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(2, 2fr)',
        md: 'repeat(4, 1fr)',
      }}
      sx={{
        mt: 4,
        gap: { xs: 2, md: 4 },
      }}
    >
      {React.Children.toArray(
        STEPS.map(({ step, title }) => (
          <ConditionalWrap
            condition={mdUp}
            wrap={(children) => (
              <Card
                sx={{
                  backgroundColor: 'transparent',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1,
                    borderRadius: 'inherit',
                    padding: pxToRem(2),
                    background:
                      hoveredCard === step
                        ? `conic-gradient(
                        ${palette.primary.main} ${progress}deg,
                        transparent ${progress}deg
                      )`
                        : 'transparent',
                    WebkitMask: `linear-gradient(#fff 0 0) content-box, 
                      linear-gradient(#fff 0 0) padding-box`,
                    mask: `linear-gradient(#fff 0 0) content-box, 
                      linear-gradient(#fff 0 0) padding-box`,
                    maskComposite: 'exclude',
                  },
                }}
                onMouseEnter={handleMouseEnter(step)}
                onMouseLeave={handleMouseLeave}
              >
                {children}
              </Card>
            )}
          >
            <Stack
              component={Card}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                height: pxToRem(186),
                gap: 1,
                bgcolor: { xs: 'grey.700', md: 'transparent' },
                cursor: { xs: 'default', md: 'pointer' },
              }}
            >
              <Box
                sx={{
                  borderRadius: '100%',
                  position: { xs: 'absolute', md: 'relative' },
                  width: pxToRem(40),
                  height: pxToRem(40),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: { xs: 10, md: 0 },
                  left: { xs: 10, md: 0 },
                  border: `1px solid ${palette.text.primary}`,
                }}
              >
                <Typography>{step}</Typography>
              </Box>

              <Typography
                sx={{
                  fontWeight: typography.fontWeightLight,
                  fontFamily: typography.button.fontFamily,
                  textAlign: 'center',
                }}
              >
                <Trans
                  components={{
                    br: <br />,
                  }}
                  i18nKey={title}
                />
              </Typography>
            </Stack>
          </ConditionalWrap>
        ))
      )}
    </Box>
  );
};

const STEPS = [
  {
    step: 1,
    title: 'landing_page.step_create_title',
  },
  {
    step: 2,
    title: 'landing_page.step_upload_title',
  },
  {
    step: 3,
    title: 'landing_page.step_receive_title',
  },
  {
    step: 4,
    title: 'landing_page.step_share_title',
  },
];
