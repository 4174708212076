import axios from 'axios';
import { max, min } from 'lodash';
import {
  api,
  ChangeUserMatchFavoriteApiGamesUserMatchIdChangeFavoritePutApiArg,
  ChangeUserMatchFavoriteApiGamesUserMatchIdChangeFavoritePutApiResponse,
  LikeHighlightApiHighlightsLikeHighlightIdPutApiArg,
  LikeHighlightApiHighlightsLikeHighlightIdPutApiResponse,
  UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiArg,
  UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiResponse,
  useGetUserMatchListApiGamesGetQuery,
  UserMatchResult,
} from './api';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ACCESS_TOKEN_KEY, API_BASE_URL } from '../../config';
import { removeUploadingDemoByHash, setUploadingDemosWithProgress } from '../redux';
import Cookies from 'js-cookie';

const addTagTypes = ['user'];

// These are non-typed endpoints, these are implemented because of non-supported django serializer types (Backend API).
// Use primary api endpoints.
const userResource = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      markFavoriteMatch: builder.mutation<
        ChangeUserMatchFavoriteApiGamesUserMatchIdChangeFavoritePutApiResponse,
        ChangeUserMatchFavoriteApiGamesUserMatchIdChangeFavoritePutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/games/${queryArg.userMatchId}/change-favorite`,
          method: 'PUT',
        }),
        async onQueryStarted({ userMatchId }, { dispatch, queryFulfilled, getState }) {
          const highlightsCacheEntires = api.util.selectInvalidatedBy(getState(), [
            { type: 'matches' },
          ]);

          const patchResults: PatchCollection[] = [];

          highlightsCacheEntires
            .filter(({ endpointName }) => endpointName === 'getUserMatchListApiGamesGet')
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    endpointName as 'getUserMatchListApiGamesGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.results.map(({ id, is_favorite, ...other }) => {
                        if (id === userMatchId) {
                          return {
                            ...other,
                            id,
                            is_favorite: !is_favorite,
                          };
                        }

                        return { ...other, id, is_favorite };
                      });
                      draft.results = source;
                    }
                  )
                )
              );
            });

          try {
            await queryFulfilled;
          } catch {
            patchResults.forEach((patch) => patch.undo());
          }
        },
      }),

      updateLikeToPost: builder.mutation<
        LikeHighlightApiHighlightsLikeHighlightIdPutApiResponse,
        LikeHighlightApiHighlightsLikeHighlightIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/highlights/like/${queryArg.highlightId}`,
          method: 'PUT',
        }),
        async onQueryStarted({ highlightId }, { dispatch, queryFulfilled, getState }) {
          const highlightsCacheEntires = api.util.selectInvalidatedBy(getState(), [
            { type: 'highlights' },
          ]);

          const patchResults: PatchCollection[] = [];

          highlightsCacheEntires
            .filter(
              ({ endpointName }) =>
                endpointName === 'getHighlightsApiHighlightsListGet' ||
                endpointName === 'getTopHighlightsApiHighlightsListTimelineGet'
            )
            .forEach(({ originalArgs, endpointName }) => {
              patchResults.push(
                dispatch(
                  api.util.updateQueryData(
                    endpointName as
                      | 'getHighlightsApiHighlightsListGet'
                      | 'getTopHighlightsApiHighlightsListTimelineGet',
                    originalArgs,
                    (draft) => {
                      const source = draft.results.map(
                        ({ is_liked, likes_count, id, ...other }) => {
                          if (id === highlightId) {
                            return {
                              ...other,
                              id,
                              likes_count: !is_liked ? likes_count + 1 : likes_count - 1,
                              is_liked: !is_liked,
                            };
                          }

                          return { ...other, id, likes_count, is_liked };
                        }
                      );
                      draft.results = source;
                    }
                  )
                )
              );
            });

          try {
            await queryFulfilled;
          } catch {
            patchResults.forEach((patch) => patch.undo());
          }
        },
      }),

      updateHighlightsSettings: builder.mutation<
        UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiResponse,
        UpdateHighlightSettingsApiHighlightSettingsHighlightSettingsPostApiArg
      >({
        query: ({ highlightSettingModelResponse }) => ({
          url: `/api/highlight-settings/highlight-settings`,
          method: 'POST',
          body: highlightSettingModelResponse,
        }),
        invalidatesTags: ['games-settings'],
        async onQueryStarted({ highlightSettingModelResponse }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData(
              'getHighlightSettingsApiHighlightSettingsHighlightSettingsGet',
              undefined,
              (draft) => {
                Object.assign(draft, highlightSettingModelResponse);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      updateAvatar: builder.mutation({
        query: (body) => ({
          url: '/api/user/update/avatar',
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['user'],
      }),
      uploadDemoWithProgress: builder.mutation({
        queryFn: async ({ hash, data }: { hash: string; data: FormData }, reduxApi) => {
          try {
            const accessToken = Cookies.get(ACCESS_TOKEN_KEY);

            const alias = (data.get('alias') as string) ?? hash;

            const result = await axios.post(`${API_BASE_URL}/api/games/upload`, data, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              onUploadProgress: (upload: any) => {
                const uploadProgress = Math.round((100 * upload.loaded) / upload.total);

                reduxApi.dispatch(
                  setUploadingDemosWithProgress({ hash, progress: uploadProgress, name: alias })
                );
              },
            });

            return { data: result.data };
          } catch (axiosError) {
            const err = axiosError as any;

            return {
              error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
              },
            };
          } finally {
            reduxApi.dispatch(removeUploadingDemoByHash(hash));
          }
        },
        invalidatesTags: ['matches'],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useLazyGetDemoByHashApiGamesDemoHashGetQuery,
  useUpdateAvatarMutation,
  useUploadDemoWithProgressMutation,
  useUpdateHighlightsSettingsMutation,
  useLazyReadCurrentUserApiUserDetailGetQuery,
  useUpdateLikeToPostMutation,
  useMarkFavoriteMatchMutation,
} = userResource;

// --------------------------------------------------------------------------------------------
// Hooks used just for change the response format
// --------------------------------------------------------------------------------------------
const useGetMyMatchesForTimeline = () =>
  useGetUserMatchListApiGamesGetQuery(
    {},
    {
      selectFromResult: ({ data, ...rest }) => ({
        data:
          (
            data?.results?.filter(
              ({ quantitative_rank, sixth_sense_rank }: UserMatchResult) =>
                quantitative_rank !== null && sixth_sense_rank !== null
            ) ?? []
          )
            .map(({ demo_id, my_result, score, map_name, created_at }: UserMatchResult) => ({
              demo_id,
              my_team_score:
                (my_result === 'VICTORY' && max(score.split(':'))) ||
                (my_result === 'LOSS' && min(score.split(':'))) ||
                (my_result === 'DRAW' && score.split(':')[0]) ||
                0,
              enemy_team_score:
                (my_result === 'VICTORY' && min(score.split(':'))) ||
                (my_result === 'LOSS' && max(score.split(':'))) ||
                (my_result === 'DRAW' && score.split(':')[0]) ||
                0,
              my_result,
              map_name,
              date_played: created_at,
            }))
            .sort((a: any, b: any) => b.date_played - a.date_played) ?? [],
        ...rest,
      }),
    }
  );

export { useGetMyMatchesForTimeline };
