import { useContext } from 'react';
import { FeatureFlagsContext, FeatureFlagsContextValue } from '../context/feature-flags-context';
import { FeatureFlagsType, FeatureResult } from '../types';

export const useFeature = (id: keyof FeatureFlagsType): FeatureResult => {
  const { features } = useContext<FeatureFlagsContextValue>(FeatureFlagsContext);

  if (!features) throw new Error('Feature flags not loaded!');

  const value = features[id];

  return { value, on: !!value, off: !value };
};
