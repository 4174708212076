import { Button, ButtonProps } from '@mui/material';
import { useLocales } from '@rankacy/intl';
import { useAuthContext } from '../../auth/hooks';
import { createUrlWithSearchParams } from '@rankacy/utils';
import { SteamActionType } from '../../auth/types';
import { buildSteamConnectReturnUrl } from './utils/build-steam-connect-return-url';
import { Iconify } from '@rankacy/iconify';

type Props = ButtonProps & {
  smallVersionWithIcon?: boolean;
  handleClick?: VoidFunction;
};

export const ConnectSteamAccountButton = ({
  handleClick,
  smallVersionWithIcon = false,
  ...props
}: Props) => {
  const { t } = useLocales();

  const { user } = useAuthContext();

  const handleConnectSteam = () => {
    handleClick?.();

    const returnTo = createUrlWithSearchParams(window.location.href, {
      action: SteamActionType.Connect,
    });

    const steamConnectUrl = buildSteamConnectReturnUrl(returnTo);

    window.location.href = steamConnectUrl;
  };

  return (
    <Button
      disabled={!!user?.steam_id}
      size="large"
      startIcon={smallVersionWithIcon ? <Iconify icon="mdi:steam" width={24} /> : undefined}
      variant="contained"
      onClick={handleConnectSteam}
      {...props}
      type="button"
    >
      {smallVersionWithIcon ? t('connect') : t('connect_with_steam')}
    </Button>
  );
};
