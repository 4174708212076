import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import HomePage from '../../pages/main/home';
import { useDashboardRoutes } from './dashboard';
import { redirectRoutes } from './redirect';
import { GuestGuard } from '../../auth/guard';
import { MainLayout } from '../../layouts/main';
import { useFeature } from '@rankacy/feature-flags';
import { lazy } from 'react';

const MaintenancePage = lazy(() => import('../../pages/maintenance'));

const useSentryRoutes = wrapUseRoutes(useRoutes);

const Router = () => {
  const dashboardRoutes = useDashboardRoutes();

  const isMaintenance = useFeature('VITE_FEATURE_MAINTENANCE').on;

  const appRoutes = useSentryRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <MainLayout>
            <Outlet />
          </MainLayout>
        </GuestGuard>
      ),
      children: [{ element: <HomePage />, index: true }],
    },
    ...authRoutes,

    ...dashboardRoutes,

    ...mainRoutes,

    ...redirectRoutes,

    { path: '*', element: <Navigate replace to="/404" /> },
  ]);

  return isMaintenance ? <MaintenancePage /> : appRoutes;
};

export default Router;
