import { alpha } from '@mui/material/styles';
import { palette as themePalette } from '../palette';

const palette = themePalette('light');

export const presetsOption = {
  palette: {
    name: 'default',
    ...palette.primary,
  },
  customShadows: {
    primary: `0 8px 16px 0 ${alpha(`${palette.primary?.main}`, 0.24)}`,
  },
};
