import { m, AnimatePresence } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { varFade } from '../animate';
import { UploadProps } from './types';
import { FileNameSetter } from './file-name-setter';
import { Iconify } from '@rankacy/iconify';
import FileThumbnail, { fileData } from '../file-thumbnail';

const MultiFilePreview = ({ thumbnail, files, onRemove, onRename, sx }: UploadProps) => (
  <AnimatePresence initial={false}>
    {files?.map((file) => {
      const { key } = fileData(file);

      if (thumbnail) {
        return (
          <Stack
            component={m.div}
            key={key}
            {...varFade().inUp}
            alignItems="center"
            display="inline-flex"
            justifyContent="center"
            sx={{
              m: 0.5,
              width: 80,
              height: 80,
              borderRadius: 1.25,
              overflow: 'hidden',
              position: 'relative',
              border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
              ...sx,
            }}
          >
            <FileThumbnail
              imageView
              tooltip
              file={file}
              imgSx={{ position: 'absolute' }}
              sx={{ position: 'absolute' }}
            />

            {onRemove && (
              <IconButton
                size="small"
                sx={{
                  p: 0.5,
                  top: 4,
                  right: 4,
                  position: 'absolute',
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  },
                }}
                onClick={() => onRemove(file)}
              >
                <Iconify icon="mingcute:close-line" width={14} />
              </IconButton>
            )}
          </Stack>
        );
      }

      return (
        <Stack
          component={m.div}
          key={key}
          {...varFade().inUp}
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{
            my: 1,
            py: 1,
            px: 1.5,
            borderRadius: 1,
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
            ...sx,
          }}
        >
          <FileThumbnail file={file} />

          <FileNameSetter file={file} onRename={onRename} />

          {onRemove && (
            <IconButton size="small" onClick={() => onRemove(file)}>
              <Iconify icon="mingcute:close-line" width={16} />
            </IconButton>
          )}
        </Stack>
      );
    })}
  </AnimatePresence>
);

export default MultiFilePreview;
