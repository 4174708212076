import { Theme } from '@mui/material/styles';

const Stepper = (theme: Theme) => ({
  MuiStepConnector: {
    styleOverrides: {
      line: {
        borderColor: theme.palette.divider,
      },
    },
  },
});

export default Stepper;
