import { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { HeaderSimple } from '../common';

type Props = {
  maxWidth?: number;
  topAlligned?: boolean;
};

export const CompactLayout = ({
  children,
  maxWidth = 400,
  topAlligned = false,
}: PropsWithChildren<Props>) => (
  <>
    <HeaderSimple />

    <Container component="main">
      <Stack
        sx={{
          py: 12,
          m: 'auto',
          maxWidth,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: topAlligned ? 'flext-start' : 'center',
        }}
      >
        {children}
      </Stack>
    </Container>
  </>
);
