import { Box, Stack, styled } from '@mui/material';

export const StyledBackgroundPattern = styled(Box)<{ src: string }>(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'repeat',
  backgroundPosition: 'center',
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: -1,
}));

export const ActiveFiltersIcon = styled(Stack)(({ theme }) => ({
  width: 7,
  height: 7,
  position: 'absolute',
  right: 5,
  top: 0,
  borderRadius: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
}));
