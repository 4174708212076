import { Theme } from '@mui/material/styles';
import { listClasses } from '@mui/material/List';
import { paper } from '../../css';

const Popover = (theme: Theme) => ({
  MuiPopover: {
    styleOverrides: {
      paper: {
        border: `1px solid ${theme.palette.divider}`,
        ...paper({ theme, dropdown: true }),
        [`& .${listClasses.root}`]: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
});

export default Popover;
