import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavItemMobileProps } from '../types';
import { ListItem } from './styles';
import { Iconify } from '@rankacy/iconify';
import { RouterLink } from '@rankacy/routing';

const NavItem = ({ item, open, active, externalLink, ...other }: NavItemMobileProps) => {
  const { title, path, icon, children } = item;

  const renderContent = (
    <ListItem active={active} {...other}>
      <ListItemIcon>{icon}</ListItemIcon>

      <ListItemText disableTypography primary={title} />

      {!!children && (
        <Iconify
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1 }}
          width={16}
        />
      )}
    </ListItem>
  );

  // External link
  if (externalLink) {
    return (
      <Link href={path} rel="noopener" target="_blank" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link component={RouterLink} href={path} underline="none">
      {renderContent}
    </Link>
  );
};

export default NavItem;
