import React from 'react';
import { useLocales } from '@rankacy/intl';
import { useDeleteFailedDemosApiGamesProcessingDeleteFailedPostMutation } from '@rankacy/services';
import { useLocation } from 'react-router';
import { BackgroundTasksIndicator } from './background-tasks-indicator';
import { useUploadDemos, useGetTmpDemos, useGetGeneratingHighlights } from './hooks';
import { BackgroundTaskItem } from './background-task-item';
import { Alert, Tooltip } from '@mui/material';
import { useBoolean } from '@rankacy/hooks';

export const BackgroundTasks = () => {
  const { t } = useLocales();

  const [clearFailed] = useDeleteFailedDemosApiGamesProcessingDeleteFailedPostMutation();

  const location = useLocation();

  const { uploadingDemosWithProgress, isUploading, numberOfUploadingDemos, isSuccess } =
    useUploadDemos();

  const { failedCount, processingCount, queuedCount, remainingProcessingSeconds } =
    useGetTmpDemos(isSuccess);

  const { generatingCount, remainingGeneratingSeconds } = useGetGeneratingHighlights();

  const renderUploading = isUploading
    ? React.Children.toArray(
        Object.entries(uploadingDemosWithProgress).map(([, { progress, name }]) => (
          <BackgroundTaskItem label={name} progress={progress} type="uploading" />
        ))
      )
    : null;

  const renderProcessingDemo = processingCount ? (
    <BackgroundTaskItem
      label={t('games_processing_count', { count: processingCount })}
      progress={remainingProcessingSeconds}
      type="processing"
    />
  ) : null;

  const renderHighlightsGenerating = generatingCount ? (
    <BackgroundTaskItem
      label={t('highlights_generating_count', { count: generatingCount })}
      progress={remainingGeneratingSeconds}
      type="processing"
    />
  ) : null;

  const alertVisible = useBoolean(true);

  const renderTasksWithProgress =
    renderProcessingDemo || renderHighlightsGenerating || renderUploading ? (
      <>
        {renderUploading}

        {renderProcessingDemo}

        {renderHighlightsGenerating}
      </>
    ) : null;
  if (location.pathname.includes('highlights-editor')) {
    return null;
  }

  return (
    <BackgroundTasksIndicator
      activeBackgroundTasks={
        processingCount + queuedCount + generatingCount + failedCount + numberOfUploadingDemos
      }
      alert={
        generatingCount && alertVisible.value ? (
          <Tooltip followCursor title={t('click_to_hide')}>
            <Alert
              severity="warning"
              sx={{ cursor: 'pointer' }}
              variant="outlined"
              onClick={alertVisible.onFalse}
            >
              {t('highlight_generation_problem')}
            </Alert>
          </Tooltip>
        ) : null
      }
      failed={failedCount ? { label: t('games_failed_count', { count: failedCount }) } : undefined}
      queued={queuedCount ? { label: t('games_queued_count', { count: queuedCount }) } : undefined}
      tasksWithProgressComponent={renderTasksWithProgress}
      onClearFailed={clearFailed}
    />
  );
};
