import { memo } from 'react';
import Stack from '@mui/material/Stack';
import { NavSectionProps, NavListProps, NavConfigProps } from '../types';
import { navMiniConfig } from '../config';
import { NavList } from './nav-list';

const NavSectionMini = ({ data, config, sx, ...other }: NavSectionProps) => (
  <Stack sx={sx} {...other}>
    {data.map((group, index) => (
      <Group config={navMiniConfig(config)} items={group.items} key={group.subheader || index} />
    ))}
  </Stack>
);

export default memo(NavSectionMini);

type GroupProps = {
  items: NavListProps[];
  config: NavConfigProps;
};

const Group = ({ items, config }: GroupProps) => (
  <>
    {items.map((list) => (
      <NavList
        config={config}
        data={list}
        depth={1}
        hasChild={!!list.children}
        key={list.title + list.path}
      />
    ))}
  </>
);
