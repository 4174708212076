import { FileRejection } from 'react-dropzone';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { fData } from '@rankacy/utils';
import { fileData } from '../../file-thumbnail';

type Props = {
  fileRejections: FileRejection[];
};

const RejectionFiles = ({ fileRejections }: Props) => {
  if (!fileRejections.length) {
    return null;
  }

  return (
    <Paper
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
      }}
      variant="outlined"
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = fileData(file);

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography noWrap variant="subtitle2">
              {path} - {size ? fData(size) : ''}
            </Typography>

            {errors.map((error) => (
              <Box component="span" key={error.code} sx={{ typography: 'caption' }}>
                - {error.message}
              </Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
};

export default RejectionFiles;
