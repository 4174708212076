import { enUS } from '@mui/material/locale';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  },
];

export const defaultLang = allLangs[0];
