import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import React from 'react';
import { useLocales } from '@rankacy/intl';
import { useResponsive } from '@rankacy/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { paths } from '../../routes/paths';
import { enqueueSnackbar } from 'notistack';
import {
  handleErrorMessage,
  useUpdateSteamCodeApiUserSteamUpdateGamecodePutMutation,
} from '@rankacy/services';
import { Iconify } from '@rankacy/iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '@rankacy/components';
import { RouterLink } from '@rankacy/routing';

const STEAM_CONNECTION_URL =
  'https://help.steampowered.com/wizard/HelpWithGameIssue/?appid=730&issueid=128&transid=3301752102843561810&line_item=3301752102843561813';

type Props = { onClose: VoidFunction; onBack?: VoidFunction } & DialogProps;

type FormValuesProps = { match_token: string; auth_code: string; agree: boolean };

export const SteamConnectProDialog = ({ open, onClose, onBack, ...other }: Props) => {
  const { t } = useLocales();

  const [updateCodes, { isLoading }] = useUpdateSteamCodeApiUserSteamUpdateGamecodePutMutation();

  const lgDown = useResponsive('down', 'lg');

  const AccountSteamSchema = Yup.object().shape({
    auth_code: Yup.string()
      .required(t('auth_code_validation'))
      .matches(/^[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}$/, t('invalid_auth_code')),
    match_token: Yup.string()
      .required(t('match_token_validation'))
      .matches(
        /^CSGO-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5,6}$/,
        t('invalid_match_token')
      ),
    agree: Yup.boolean().isTrue(t('steam_terms_agree')),
  });

  const defaultValues = {
    auth_code: '',
    match_token: '',
    agree: false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(AccountSteamSchema) as any,
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async ({ auth_code, match_token }: FormValuesProps) => {
    try {
      await updateCodes({
        steamCodeUpdateRequest: { auth_code, match_code: match_token },
      }).unwrap();
    } catch (error) {
      enqueueSnackbar(handleErrorMessage(error), { variant: 'error' });
    } finally {
      reset();
      onClose();
    }
  };

  return (
    <Dialog disableEscapeKeyDown fullWidth maxWidth="md" open={open} {...other}>
      <DialogContent
        sx={{
          background: 'url(/assets/background/steam_connect_pro_dialog_overlay.webp)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100%',
          textAlign: 'center',
          px: { xs: 2, lg: 8 },
          py: { xs: 4, lg: 6 },
        }}
      >
        <Stack gap={4}>
          <Stack
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              p: 2,
              flexDirection: 'row',
              justifyContent: onBack ? 'space-between' : 'flex-end',
              width: 1,
            }}
          >
            {onBack && (
              <IconButton disableRipple sx={{ p: 0 }} onClick={onBack}>
                <Iconify icon="mdi:chevron-left" width={24} />
              </IconButton>
            )}
            <IconButton disableRipple sx={{ p: 0 }} onClick={onClose}>
              <Iconify icon="mdi:close" width={24} />
            </IconButton>
          </Stack>

          <Typography color="text.secondary" variant="subtitle1">{`${t(
            'one_last_step'
          )} ...`}</Typography>

          <Typography variant={lgDown ? 'h5' : 'h3'}>{t('steam_connect_pro_title')}</Typography>

          <Typography sx={{ textAlign: 'left' }}>
            <Typography component="span">
              {t('steam_connect_step_one')}{' '}
              <Link
                color="primary.main"
                component={RouterLink}
                fontWeight="bold"
                href={STEAM_CONNECTION_URL}
                target="_blank"
              >
                {t('here')}
              </Link>
            </Typography>
            <br />
            {t('steam_connect_step_two')}
            <br />
            {t('steam_connect_step_three')}
            <br />
            {t('steam_connect_step_four')}
          </Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2} sx={{ maxWidth: 390, mx: 'auto' }}>
              <Typography textAlign="left">{`${t('paste_codes')}:`}</Typography>
              <RHFTextField label={t('match_token')} name="match_token" />
              <RHFTextField label={t('auth_code')} name="auth_code" />

              <Stack gap={1}>
                <LoadingButton
                  loading={isLoading}
                  size="large"
                  sx={{ width: 'fit-content', mx: 'auto', mt: 2 }}
                  type="submit"
                  variant="contained"
                >
                  {t('connect_demos')}
                </LoadingButton>

                <RHFCheckbox
                  label={
                    <Typography
                      color="text.secondary"
                      sx={{ maxWidth: 330 }}
                      textAlign="left"
                      variant="body2"
                    >
                      {t('accept_steam_connect')}
                    </Typography>
                  }
                  name="agree"
                />
              </Stack>
            </Stack>
          </FormProvider>

          {onBack && (
            <Button
              LinkComponent={RouterLink}
              href={paths.dashboard.match.list}
              sx={{ width: 'fit-content', mx: 'auto' }}
              variant="text"
            >
              {t('continue_manually')}
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
