import { Theme } from '@mui/material/styles';

const Container = (theme: Theme) => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        '&.MuiContainer-maxWidthLg': {
          maxWidth: '1280px',
        },
      },
    },
  },
});

export default Container;
