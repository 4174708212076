import { Iconify } from '@rankacy/iconify';
import { paths } from '../../routes/paths';
import { useLocales } from '@rankacy/intl';

export const useNavData = () => {
  const { t } = useLocales();

  return [
    {
      title: t('home'),
      icon: <Iconify icon="eva:home-fill" />,
      path: '/',
      disableGuestGuard: true,
    },
    {
      title: t('about_us'),
      icon: <Iconify icon="mdi:people-group" />,
      path: paths.about,
    },
  ];
};
