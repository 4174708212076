export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80,
  H_ANNOUNCEMENT: 36,
};

export const NAV = {
  W_VERTICAL: 244,
  W_MINI: 88,
};

export const HIGHLIGHTS_EDITOR_MENU = {
  W_ROOT_MENU: 112,
  W_SUB_MENU: 244,
};

export const HIGHLIGHTS_EDITOR_HEADER = {
  H_DESKTOP: 107,
  H_MOBILE: 64,
};
