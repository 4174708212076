import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { useLocales } from '@rankacy/intl';
import { pxToRem } from '@rankacy/theme';

type Props = {
  title: string;
  subTitle: string;
  image?: string;
};

export const HomeFeatureCard = ({ title, subTitle, image }: Props) => {
  const { t } = useLocales();

  const { typography, palette } = useTheme();

  return (
    <Card
      sx={{
        height: { xs: 204, md: 380, lg: 480 },
        width: 1,
        background: `url(${image})`,
        backgroundColor: palette.grey[700],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: { xs: 'top right', md: 'top center' },
        backgroundSize: { xs: 'auto 100%', md: '100%' },
      }}
    >
      <CardContent sx={{ height: 1, width: { xs: '70%', sm: '50%', md: 1 } }}>
        <Stack
          sx={{
            justifyContent: 'flex-start',
            height: '100%',
            alignItems: 'flex-end',
            flexDirection: 'row',
          }}
        >
          <Stack
            sx={{
              gap: { xs: 1, md: 2 },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: pxToRem(24), lg: pxToRem(32) },
                fontFamily: typography.h1.fontFamily,
                fontWeight: typography.fontWeightMedium,
              }}
            >
              {t(title)}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: pxToRem(13), lg: pxToRem(16) },
                fontFamily: typography.h3.fontFamily,
                fontWeight: typography.fontWeightLight,
              }}
            >
              {t(subTitle)}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
