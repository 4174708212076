import { useHasFocus, useLocalStorageSyncedState } from '@rankacy/hooks';
import { getUniqueTabId } from '@rankacy/utils';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

const LAST_ACTIVE_TAB_ID_KEY = 'last-active-tab-id';

/**
 * `useIsTrackerActive` is a custom hook that information about tracker (new events ws) activity.
 * It uses useHasFocus hook to check if current tab is focused.
 * It uses useLocalStorageSyncedState hook to store last active tab id.
 * If there is change in currentTabFocus and lastActiveTabId, it updates lastActiveTabId.
 *
 * @returns {boolean} - An boolean with a `isTrackerActive` value.
 */
export const useIsTrackerActive = (): boolean => {
  const isCurrentTabFocus = useHasFocus();

  const [lastActiveTabId, setLastActiveTabId] =
    useLocalStorageSyncedState<string>(LAST_ACTIVE_TAB_ID_KEY);

  const currentTabId = getUniqueTabId();

  useEffect(() => {
    if (isCurrentTabFocus && currentTabId !== lastActiveTabId) {
      setLastActiveTabId(currentTabId);
    }
  }, [isCurrentTabFocus, currentTabId, lastActiveTabId, setLastActiveTabId]);

  return isEqual(currentTabId, lastActiveTabId);
};

// TODO: If there would be requirement that new message should be visible in tab title,
// there needs to be implemented( for example stack) to handle setting new active tab after
// closing current tab and go to different app tab.
