import { createContext } from 'react';
import { FeatureFlagsType } from '../types';

export type FeatureFlagsContextValue = {
  features: FeatureFlagsType | null;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>({
  features: null,
});
