import React from 'react';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { TaskItem } from './styles';
import { Iconify } from '@rankacy/iconify';
import { CircularProgressWithValue } from '@rankacy/components';

type Props = {
  type: 'processing' | 'uploading' | 'failed' | 'queued';
  label: string;
  progress?: number;
  onClearFailed?: () => void;
};

export const BackgroundTaskItem = ({ type, label, progress, onClearFailed }: Props) => {
  const renderStartIcon = (type === 'failed' && (
    <Iconify
      color={type === 'failed' ? 'error.main' : 'text.secondary'}
      icon="mdi:alert-circle"
      width={24}
    />
  )) ||
    (type === 'processing' && (
      <Iconify color="text.secondary" icon="mdi:information" width={24} />
    )) ||
    (type === 'uploading' && <Iconify color="text.secondary" icon="mdi:upload" width={24} />) || (
      <Iconify color="text.secondary" icon="mdi:timer-sand" width={24} />
    );

  const renderEndElement =
    (type === 'failed' && (
      <IconButton sx={{ p: 0 }} onClick={onClearFailed}>
        <Iconify color="background.default" icon="mdi:remove" width={24} />
      </IconButton>
    )) ||
    (type === 'uploading' && <CircularProgressWithValue color="inherit" value={progress || 0} />) ||
    (type === 'processing' && (
      // <Typography color="text.primary" component="div" variant="caption">
      //   {fSeconds(progress || 0)}
      // </Typography>
      <CircularProgress color="inherit" size={24} />
    )) ||
    (type === 'queued' && null);

  return (
    <TaskItem disabled={type === 'queued'} variant={(type === 'failed' && 'error') || 'info'}>
      <Stack
        sx={{ flexDirection: 'row', flexGrow: 1, gap: 1, alignItems: 'center', overflow: 'hidden' }}
      >
        {renderStartIcon}

        <Typography noWrap variant="body2">
          {label}
        </Typography>
      </Stack>

      {renderEndElement}
    </TaskItem>
  );
};
