import { m } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import { Box, Divider, Link } from '@mui/material';
import { RouterLink } from '@rankacy/routing';
import { paths } from '../../routes/paths';
import { useLocales } from '@rankacy/intl';
import { useResponsive } from '@rankacy/hooks';
import { Logo } from '@rankacy/components';

type Props = {
  children: React.ReactNode;
  type?: 'simple' | 'image';
};

export const AuthLayout = ({ children, type = 'simple' }: PropsWithChildren<Props>) => {
  const { t } = useLocales();

  const upMd = useResponsive('up', 'md');

  const renderPoliciesLinks = (
    <Stack
      divider={
        <Divider
          flexItem
          orientation="vertical"
          sx={{ borderColor: ({ palette }) => palette.grey[600] }}
        />
      }
      sx={{ flexDirection: 'row', gap: 2, justifyContent: 'center', my: 5 }}
    >
      <Link
        color="text.secondary"
        component={RouterLink}
        href={paths.termsAndConditions}
        variant="body2"
      >
        {t('terms_conditions')}
      </Link>

      <Link
        color="text.secondary"
        component={RouterLink}
        href={paths.privacyPolicy}
        variant="body2"
      >
        {t('privacy_policy')}
      </Link>
    </Stack>
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 600,
        justifyContent: 'space-between',
        px: { xs: 2, md: 13 },
        overflow: 'hidden',
        zIndex: 2,
        flexGrow: 1,
      }}
    >
      <Stack sx={{ flexGrow: 1, justifyContent: 'space-evenly', gap: 2, pt: 2 }}>
        <Logo
          logoHeight={type === 'image' ? 52 : 80}
          sx={{ mx: 'auto' }}
          variant={type === 'image' ? 'text' : 'small'}
        />

        <Stack>{children}</Stack>
      </Stack>

      {renderPoliciesLinks}
    </Stack>
  );

  const renderBackground = () => {
    if (type === 'image') {
      return upMd ? (
        <>
          <Stack sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              overflow: 'hidden',
            }}
          >
            <Box
              animate={{
                transform: [
                  'rotate(0deg)  translateX(10px) rotate(0deg)',
                  'rotate(360deg)  translateX(10px) rotate(-360deg)',
                ],
              }}
              component={m.div}
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                background:
                  'url(/assets/images/home/logo-levitation.webp), url(/assets/background/login-logo-bg-ellipse.webp)',
                backgroundSize: {
                  md: '804px',
                  lg: '1136px ',
                },
                backgroundRepeat: 'no-repeat',
                backgroundPosition: { md: 'left', xl: '30%' },
              }}
              transition={{
                duration: 10,
                repeat: Infinity,
                repeatType: 'loop',
                ease: 'linear',
              }}
            />
          </Box>
        </>
      ) : (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            height: '33%',
          }}
        />
      );
    }

    return null;
  };

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        background: `url(${'/assets/images/maps/de_inferno.webp'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      {renderBackground()}

      {renderContent}
    </Stack>
  );
};
