import { uniqueId } from 'lodash';

export const getUniqueTabId = () => {
  let value = window.sessionStorage.getItem('uniqueTabId');

  if (!value || !window.name) {
    value = getUniqueId();
    window.sessionStorage.setItem('uniqueTabId', value);
  }

  window.name = value;

  return value;
};

const getUniqueId = () => {
  const prefix = new Date().getTime().toString();

  return uniqueId(prefix);
};
