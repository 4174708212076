import { useEffect, useState, PropsWithChildren } from 'react';
import { paths } from '../../routes/paths';
import { useLocation } from 'react-router';
import { useAuthContext } from '../hooks';
import { useRouter } from '@rankacy/routing';

const loginPaths: Record<string, string> = {
  jwt: paths.login,
};

const AuthGuard = ({ children }: PropsWithChildren) => {
  const router = useRouter();

  const { authenticated, method } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const { pathname: returnTo } = useLocation();

  const check = () => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({ returnTo }).toString();

      const loginPath = loginPaths[method];

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  };

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};

export default AuthGuard;
