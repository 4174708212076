import Box, { BoxProps } from '@mui/material/Box';
import { useResponsive } from '@rankacy/hooks';
import { useSettingsContext } from '@rankacy/settings';
import { HEADER, NAV } from '../config-layout';

const SPACING = { DESKTOP: 16, MOBILE: 8 };

type Props = BoxProps & {
  disablePadding?: boolean;
};

const Main = ({ children, disablePadding, sx, ...other }: Props) => {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const isNavMini = settings.themeLayout === 'mini';

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        py: `${HEADER.H_MOBILE + SPACING.MOBILE}px`,
        ...(disablePadding && {
          py: 0,
        }),
        ...(lgUp && {
          px: 2,
          py: `${SPACING.DESKTOP}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(disablePadding && {
            px: 0,
            py: 0,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default Main;
