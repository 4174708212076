import { useTheme } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useResponsive } from '@rankacy/hooks';
import { useSettingsContext } from '@rankacy/settings';
import { NAV } from '../config-layout';
import { bgBlur } from '@rankacy/theme';
import { Iconify } from '@rankacy/iconify';

const NavToggleButton = ({ sx, ...other }: IconButtonProps) => {
  const theme = useTheme();

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      size="small"
      sx={{
        p: 0.5,
        top: { sx: 64, md: 48 },
        position: 'fixed',
        left: NAV.W_VERTICAL - 12,
        zIndex: theme.zIndex.appBar + 1,
        border: `solid 1px ${theme.palette.border}`,
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      onClick={() =>
        settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical')
      }
      {...other}
    >
      <Iconify
        color={theme.palette.text.primary}
        icon={
          settings.themeLayout === 'vertical'
            ? 'eva:arrow-ios-back-fill'
            : 'eva:arrow-ios-forward-fill'
        }
        width={16}
      />
    </IconButton>
  );
};

export default NavToggleButton;
