import './sentry-instrument';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'regenerator-runtime';
import { HelmetProvider } from 'react-helmet-async';
import App from './app';
import { GTM_ID } from './config-global';
import { initIntl, Namespaces } from '@rankacy/intl';
import { initializeGoogleTagManager } from '@rankacy/analytics';

initializeGoogleTagManager({ gtmId: GTM_ID });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initIntl(Namespaces.Rankacy);

root.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
);
